import React from 'react';
import { Confirm, ConfirmProps } from 'semantic-ui-react';

export type IATMConfirmProps = ConfirmProps;

const ATMConfirm: React.FC<ConfirmProps> = ({ ...props }) => (
  <Confirm {...props} />
);

export { ATMConfirm };
