import React from 'react';
import { Icon, IconProps, IconGroupProps } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMIcon.module.scss';

export type ATMIconProps = IconProps & {
  borderLess?: boolean;
};

export interface IATMIcon extends React.FC<ATMIconProps> {
  Group: typeof Icon.Group;
}

const ATMIcon: IATMIcon = ({ children, ...props }) => (
  <Icon
    {...props}
    className={classNames(props.className, {
      [styles.borderless]: styles.borderless,
    })}
  >
    {children}
  </Icon>
);

ATMIcon.Group = Icon.Group;

export type IATMIconProps = IconProps;
export type IATMIconGroupProps = IconGroupProps;

export { ATMIcon };
