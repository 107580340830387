import React from 'react';
import {
  Grid,
  GridProps,
  GridColumn,
  GridRow,
  GridColumnProps,
  GridRowProps,
} from 'semantic-ui-react';

export interface IATMGrid extends React.FC<GridProps> {
  Column: typeof GridColumn;
  Row: typeof GridRow;
}

const ATMGrid: IATMGrid = ({ children, ...props }) => {
  return <Grid {...props}>{children}</Grid>;
};

ATMGrid.Column = Grid.Column;
ATMGrid.Row = Grid.Row;

export type IATMGridProps = GridProps;
export type IATMGridColumnProps = GridColumnProps;
export type IATMGridRowProps = GridRowProps;

export { ATMGrid };
