import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { ATMMessage } from '../../atoms/ATMMessage/ATMMessage.component';
import styles from './MOLUserAgent.module.scss';
import { getBrowser } from './UserAgent';

export type IMOLUserAgentProps = {
  header?: string | undefined;
  content?: string | undefined;
};

const MOLUserAgent: React.FC<IMOLUserAgentProps> = ({
  header = "It looks like you may be using a web browser that we don't support.",
  content = 'Please use one of the supported browsers - Latest Version of Google Chrome or Microsoft Edge to get the optimal experience',
}) => {
  const [viewMsg, setMsg] = useState(false);
  const cookies = new Cookies();
  // const [showMsg, setShowMsg] = useState<boolean>(false);

  const handleOnDismiss = () => {
    setMsg(false);
    cookies.set('newCookie', 'cookieVal', {
      path: '/',
      expires: moment().add(24, 'hours').toDate(),
    });
  };
  useEffect(() => {
    const browserName = getBrowser();
    if (browserName !== 'chrome' && browserName !== 'edge') {
      setMsg(true);
    }
  }, []);

  const checkCookie = cookies.get('newCookie');
  return (
    <div>
      {viewMsg && (
        <div>
          {checkCookie !== 'cookieVal' && (
            <ATMMessage
              info
              className={styles.userAgentMessage}
              header={header}
              content={content}
              onDismiss={handleOnDismiss}
            />
          )}
        </div>
      )}
    </div>
  );
};

export { MOLUserAgent };
