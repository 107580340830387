export function capitalizeFirstLetter(str) {
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function letterCaseWord(str) {
  return str.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
}

export function secondLargestValue(arr) {
  const secondHighest = arr.sort((a, b) => {
    return b - a;
  })[1];
  return secondHighest;
}
