import React from 'react';
import DatePicker from 'react-semantic-ui-datepickers';
import { SemanticDatepickerProps } from 'react-semantic-ui-datepickers/dist/types';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import classNames from 'classnames';
import styles from './ATMDatePicker.module.scss';
import { ATMLabel } from '../ATMLabel/ATMLabel.component';

export type IATMDatePickerProps = Partial<SemanticDatepickerProps> & {
  error?: string | undefined;
  size?: 'small' | 'large' | 'huge';
};

export const ATMDatePicker: React.FC<IATMDatePickerProps> = ({
  error,
  size = 'small',
  ...props
}) => {
  return (
    <div className={error === undefined ? ' ' : styles.errorField}>
      <DatePicker
        className={classNames(props.className, {
          [styles.small]: size === 'small',
          [styles.large]: size === 'large',
          [styles.huge]: size === 'huge',
        })}
        {...props}
      />
      {error && (
        <div>
          <ATMLabel basic className={styles.errorLabel} pointing="above">
            {error}
          </ATMLabel>
        </div>
      )}
    </div>
  );
};
