import React from 'react';
import {
  PlaceholderProps,
  Placeholder,
  PlaceholderHeaderProps,
  PlaceholderImageProps,
  PlaceholderLineProps,
} from 'semantic-ui-react';

export interface IATMPlaceholder extends React.FC<PlaceholderProps> {
  Header: typeof Placeholder.Header;
  Image: typeof Placeholder.Image;
  Line: typeof Placeholder.Line;
  Paragraph: typeof Placeholder.Paragraph;
}

const ATMPlaceholder: IATMPlaceholder = ({ children, ...props }) => (
  <Placeholder {...props}>{children}</Placeholder>
);

ATMPlaceholder.Header = Placeholder.Header;
ATMPlaceholder.Image = Placeholder.Image;
ATMPlaceholder.Line = Placeholder.Line;
ATMPlaceholder.Paragraph = Placeholder.Paragraph;

export type IATMPlaceholderProps = PlaceholderProps;
export type IATMPlaceholderHeaderProps = PlaceholderHeaderProps;
export type IATMPlaceholderImageProps = PlaceholderImageProps;
export type IATMPlaceholderLineProps = PlaceholderLineProps;

export { ATMPlaceholder };
