import React from 'react';
import { BarChart, PieChart, BarProps, PieProps } from 'recharts';
import { LayoutType, Margin } from 'recharts/types/util/types';
import styles from './ATMCharts.module.scss';

export type IATMChartProps = BarProps &
  PieProps & {
    data?: any;
    xlabel?: string;
    ylabel?: string;
    chartType?: string;
    layout?: LayoutType;
    title?: string;
    width?: number;
    height?: number;
    margin?: Margin;
  };

const ATMChart: React.FC<IATMChartProps> = ({ children, ...props }) => {
  const { data, xlabel, ylabel } = props;

  if (props.chartType === 'composed-vertical') {
    return (
      <div className={styles.main}>
        <div className={styles.yAxisLabel}>
          <p>{ylabel}</p>
        </div>
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <BarChart
          margin={props.margin}
          barCategoryGap={17}
          barGap={5}
          layout={props.layout}
          width={props.width}
          height={props.height}
          data={data}
        >
          {children}
        </BarChart>
        {/* </ResponsiveContainer> */}
        <div className={styles.xAxisLabel}>
          <p>{xlabel}</p>
        </div>
      </div>
    );
  }

  if (props.chartType === 'pie-hollow') {
    return (
      <div className={styles.main}>
        <PieChart height={props.height} width={props.width}>
          {children}
        </PieChart>

        <div>
          <p>{xlabel}</p>
        </div>
      </div>
    );
  }

  return null;
};

export { ATMChart };
