import React from 'react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLNoData.module.scss';

export type IMOLNoDataProps = {
  icon?: SemanticICONS;
  header?: string;
  subHeader?: string;
};

const MOLNoData: React.FC<IMOLNoDataProps> = ({ icon, header, subHeader }) => {
  return (
    <div className={styles.noData}>
      <ATMIcon name={icon} size="big" />
      <ATMHeader as="h3" className={styles.sempraTinyMarginBottom}>
        {header}
      </ATMHeader>
      <p style={{ fontSize: '12px' }}>{subHeader}</p>
    </div>
  );
};

export { MOLNoData };
