import React from 'react';
import classNames from 'classnames';
import {
  ModalProps,
  Modal,
  ModalActionsProps,
  ModalContentProps,
  ModalDescriptionProps,
  ModalHeaderProps,
} from 'semantic-ui-react';
import styles from './ATMModal.module.scss';

export interface IATMModal extends React.FC<ModalProps> {
  Actions: typeof Modal.Actions;
  Content: typeof Modal.Content;
  Description: typeof Modal.Description;
  Header: typeof Modal.Header;
}

const ATMModal: IATMModal = ({ children, ...props }) => (
  <Modal
    {...props}
    className={classNames(props.className, {
      [styles.fullscreen]: props.size === 'fullscreen',
    })}
  >
    {children}
  </Modal>
);

ATMModal.Actions = Modal.Actions;
ATMModal.Content = Modal.Content;
ATMModal.Description = Modal.Description;
ATMModal.Header = Modal.Header;

export type IATMModalProps = ModalProps;
export type IATMModalActionsProps = ModalActionsProps;
export type IATMModalContentProps = ModalContentProps;
export type IATMModalDescriptionProps = ModalDescriptionProps;
export type IATMModalHeaderProps = ModalHeaderProps;

export { ATMModal };
