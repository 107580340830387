import React, { useRef, useEffect, useState } from 'react';
import { Card, Ref } from 'semantic-ui-react';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import { ATMButton } from '../../atoms/ATMButton/ATMButton.component';
import { ATMGrid } from '../../atoms/ATMGrid/ATMGrid.component';
import { MOLReadonlyField } from '../MOLReadonlyField/MOLReadonlyField.component';
import styles from './MOLInfoCard.module.scss';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';
import { ATMImage } from '../../atoms/ATMImage/ATMImage.component';
import { ATMDropdown } from '../../atoms/ATMDropdown/ATMDropdown.component';

export type btn = 'primary' | 'secondary';

export type IMOLInfoCardProps = {
  cardHeader: string;
  cardSubHeader?: string;
  cardContent?: string;
  customCardContent?: any;
  additionalDetails?: Record<string, any>;
  profileDetails?: Record<string, string>;
  width?: string;
  onNominate?: () => void;
  iconName?: any;
  showIcon?: boolean;
  onHeaderClick?: () => void;
  showProfiles?: () => void;
  onViewMore?: () => void;
  onNavigate?: string;
  imageCount?: number;
  profileHeader?: string;
  btnContent?: string;
  btnType?: btn;
  showActionIcon?: boolean;
  showProfileImage?: boolean;
  profileImage?: string;
  dueData?: Record<string, any>;
  cardHeaderStyle?: string;
  smallCard?: boolean;
  iconColor?: any;
  iconSize?: any;
  profileImageFloat?: any;
  profileImageSize?: any;
  onEditClick?: () => void;
  onListClick?: () => void;
  onDeleteClick?: () => void;
};

const MOLInfoCard: React.FC<IMOLInfoCardProps> = ({
  cardHeader,
  cardSubHeader,
  cardContent,
  additionalDetails,
  width = '100%',
  onNominate,
  iconName = 'trophy',
  showIcon = false,
  customCardContent,
  profileDetails,
  onHeaderClick,
  showProfiles,
  onViewMore,
  onNavigate = '#',
  imageCount,
  profileHeader,
  btnContent = 'Nominate',
  btnType = 'primary',
  showActionIcon,
  showProfileImage,
  profileImage,
  dueData,
  cardHeaderStyle,
  smallCard,
  iconColor = 'yellow',
  iconSize = 'large',
  profileImageFloat = 'left',
  profileImageSize = 'mini',
  onEditClick,
  onListClick,
  onDeleteClick,
  ...props
}) => {
  const cardWidth = parseInt(width, 10);
  const targetRef: any = useRef();
  const [totalWidth, setTotalWidth] = useState(0);
  const colors = ['#DB9425', '#126398', '#833333', '#256F18', '#674589'];
  const profileImg = profileDetails ? Object.keys(profileDetails).length : 0;
  const textTruncate = (str: any, length: number) => {
    const ending = '...';
    if (str.length > length) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return str.substring(0, length - ending.length) + ending;
    }
    return str;
  };

  useEffect(() => {
    if (targetRef.current) {
      setTotalWidth(targetRef.current.offsetWidth);
    }
  }, [targetRef.current]);

  const getImgNumber = () => {
    if (imageCount) {
      return imageCount;
    }
    if (totalWidth < 1400 && totalWidth >= 900) {
      if (profileImg > 15) {
        return 15;
      }
      return profileImg;
    }
    if (totalWidth < 900 && totalWidth >= 800) {
      if (profileImg > 12) {
        return 12;
      }
      return profileImg;
    }
    if (totalWidth < 800 && totalWidth >= 700) {
      if (profileImg > 10) {
        return 10;
      }
      return profileImg;
    }
    if (totalWidth < 700 && totalWidth >= 500) {
      if (profileImg > 6) {
        return 6;
      }
      return profileImg;
    }
    if (totalWidth < 500 && totalWidth >= 400) {
      if (profileImg > 4) {
        return 4;
      }
      return profileImg;
    }

    if (totalWidth < 400 && totalWidth >= 300) {
      if (profileImg > 2) {
        return 2;
      }
      return profileImg;
    }

    if (totalWidth < 300 && totalWidth >= 100) {
      if (profileImg > 1) {
        return 1;
      }
      return profileImg;
    }

    return 4;
  };

  const img = Number(getImgNumber());

  return (
    <div style={{ display: 'inline' }}>
      <Ref innerRef={targetRef}>
        <Card style={{ width }} className={styles.infocard}>
          <Card.Content className={styles.content}>
            {smallCard ? (
              <ATMGrid>
                <ATMGrid.Row>
                  <ATMGrid.Column computer="10" mobile="8" tablet="9">
                    {showIcon && (
                      <ATMIcon
                        circular
                        color={iconColor}
                        size={iconSize}
                        className={styles.cardIcon}
                        name={iconName}
                      />
                    )}
                    {showProfileImage && (
                      <ATMImage
                        floated={profileImageFloat}
                        size={profileImageSize}
                        src={profileImage}
                        alt="Profile Image"
                      />
                    )}
                    <div className={styles.headerTitle}>
                      <Card.Header
                        className={
                          // eslint-disable-next-line no-nested-ternary
                          cardHeaderStyle === 'blue'
                            ? styles.blueHeader
                            : cardHeaderStyle === 'blackWithoutUnderline'
                            ? styles.blackHeader
                            : styles.header
                        }
                        onClick={onHeaderClick}
                      >
                        <ATMPopover
                          inverted
                          className={styles.delay}
                          trigger={<div>{cardHeader} </div>}
                          content={cardHeader}
                          size="mini"
                        />
                      </Card.Header>
                      {cardSubHeader && (
                        <Card.Meta className={styles.metaHeader}>
                          <ATMPopover
                            inverted
                            className={styles.delay}
                            trigger={<div>{cardSubHeader} </div>}
                            content={cardSubHeader}
                            size="mini"
                          />
                        </Card.Meta>
                      )}
                    </div>
                  </ATMGrid.Column>
                  <ATMGrid.Column computer="6" mobile="8" tablet="7">
                    {showActionIcon && (
                      <div className={styles.actionIcons}>
                        <ATMIcon
                          name="edit outline"
                          title="Edit"
                          className={styles.actionIconStyle}
                          onClick={onEditClick}
                        />
                        <ATMIcon
                          name="ordered list"
                          title="List"
                          className={styles.actionIconStyle}
                          onClick={onListClick}
                        />
                        <ATMDropdown
                          icon="ellipsis horizontal"
                          floating
                          className="icon"
                          direction="left"
                        >
                          <ATMDropdown.Menu>
                            <ATMDropdown.Item
                              icon="trash alternate outline"
                              text="Delete"
                              onClick={onDeleteClick}
                            />
                            <ATMDropdown.Item icon="delete" text="Close" />
                            <ATMDropdown.Item icon="delete" text="Suspend" />
                          </ATMDropdown.Menu>
                        </ATMDropdown>
                      </div>
                    )}
                  </ATMGrid.Column>
                </ATMGrid.Row>
              </ATMGrid>
            ) : (
              <div className={styles.flex}>
                {showIcon && (
                  <ATMIcon
                    circular
                    color={iconColor}
                    size={iconSize}
                    className={styles.cardIcon}
                    name={iconName}
                  />
                )}

                {showProfileImage && (
                  <ATMImage
                    floated={profileImageFloat}
                    size={profileImageSize}
                    src={profileImage}
                    alt="Profile Image"
                  />
                )}
                <div className={styles.headerTitle}>
                  <Card.Header
                    as="h1"
                    onClick={onHeaderClick}
                    className={
                      // eslint-disable-next-line no-nested-ternary
                      cardHeaderStyle === 'blue'
                        ? styles.blueHeader
                        : cardHeaderStyle === 'blackWithoutUnderline'
                        ? styles.blackHeader
                        : styles.header
                    }
                  >
                    {cardHeader}
                  </Card.Header>
                  {cardSubHeader && (
                    <Card.Meta className={styles.metaHeader}>
                      {cardSubHeader}
                    </Card.Meta>
                  )}
                </div>
              </div>
            )}

            <Card.Description className={styles.description}>
              {cardContent && (
                <div className={styles.cardContent}>
                  <p className={styles.infoContent}>
                    {textTruncate(cardContent, 250)}
                  </p>
                  {cardContent && cardContent.length > 250 && (
                    <span>
                      {' '}
                      <a
                        href={onNavigate}
                        className="sempraSecondaryLink"
                        onClick={onViewMore}
                      >
                        View More
                      </a>
                    </span>
                  )}
                </div>
              )}
              {customCardContent}
              {additionalDetails && (
                <ATMGrid>
                  {Object.keys(additionalDetails).map((d) => (
                    <ATMGrid.Column
                      className={styles.gridcol}
                      computer={cardWidth > 300 || width === '100%' ? 8 : 16}
                      tablet={4}
                      mobile={16}
                      key={d}
                    >
                      <MOLReadonlyField
                        {...props}
                        label={d}
                        value={additionalDetails[String(d)]}
                        inline={false}
                      />
                    </ATMGrid.Column>
                  ))}
                </ATMGrid>
              )}
              {dueData && (
                <ATMGrid>
                  {Object.keys(dueData).map((d) => (
                    <ATMGrid.Column
                      className={styles.gridcol}
                      computer={cardWidth > 300 || width === '100%' ? 8 : 16}
                      tablet={4}
                      mobile={16}
                      key={d}
                    >
                      <MOLReadonlyField
                        {...props}
                        label={d}
                        value={dueData[String(d)]}
                        inline={false}
                      />
                    </ATMGrid.Column>
                  ))}
                </ATMGrid>
              )}
            </Card.Description>
            {profileDetails && (
              <div className={styles.tinyMarginTop}>
                <p className={styles.profileHeaderText}>
                  {profileHeader} ({profileImg})
                </p>
                <div className={styles.nominee}>
                  <div className={styles.profiles}>
                    {Object.keys(profileDetails)
                      .slice(0, img)
                      .map((src, key) => {
                        if (profileDetails[String(src)] === '') {
                          const fullName = src.split(' ');
                          let initials = '';
                          if (fullName.length === 1) {
                            initials =
                              fullName.shift()?.charAt(0)?.toUpperCase() || '';
                          } else {
                            const firstChar = fullName
                              .shift()
                              ?.charAt(0)
                              .toUpperCase();
                            const lastChar = fullName
                              .pop()
                              ?.charAt(0)
                              .toUpperCase();
                            let fullChar = '';
                            if (firstChar && lastChar) {
                              fullChar = firstChar + lastChar;
                            }
                            initials = fullChar;
                          }

                          return (
                            <ATMPopover
                              inverted
                              key={key}
                              size="mini"
                              content={src}
                              trigger={
                                <span
                                  style={{
                                    backgroundColor:
                                      colors[
                                        Math.floor(
                                          Math.random() * colors.length
                                        )
                                      ],
                                  }}
                                  className={styles.dot}
                                >
                                  {initials}{' '}
                                </span>
                              }
                            />
                          );
                        }
                        return (
                          // eslint-disable-next-line react/jsx-key
                          <ATMPopover
                            inverted
                            size="mini"
                            content={src}
                            trigger={
                              <img
                                src={profileDetails[String(src)]}
                                alt="img"
                                key={key}
                              />
                            }
                          />
                        );
                      })}
                    {profileImg - img > 0 && (
                      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                      <span className={styles.dot} onClick={showProfiles}>
                        <p>+{profileImg - img}</p>
                      </span>
                    )}
                  </div>
                  <ATMButton
                    className={styles.marginTop}
                    content={btnContent}
                    primary={btnType === 'primary'}
                    secondary={btnType === 'secondary'}
                    onClick={onNominate}
                  />
                </div>
              </div>
            )}
          </Card.Content>
        </Card>
      </Ref>
    </div>
  );
};

export { MOLInfoCard };
