import React, { useEffect, useState } from 'react';
import { Step, StepProps } from 'semantic-ui-react';
import { letterCaseWord } from '../../../utils/common';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import { ATMPopover } from '../ATMPopover/ATMPopover.component';
import styles from './ATMStepper.module.scss';

export type IATMStepperProps = StepProps & {
  stepperData: any;
  wrap?: boolean;
  activeItem: string;
  handleStepperClick: any;
  vertical?: boolean;
  truncateText?: boolean;
};

export const ATMStepper: React.FC<IATMStepperProps> = (props) => {
  const {
    stepperData,
    activeItem,
    handleStepperClick,
    wrap = true,
    vertical = false,
    truncateText = false,
  } = props;

  const stepperContainer = document?.getElementById('stepperContainer');

  const [scrollingLeft, setScrollingLeft] = useState(0);
  const [stepperParentWidth, setStepperParentWidth] = useState(
    document?.getElementById('stepperParentContainer')?.scrollWidth
  );
  const [stepperWidth, setStepperWidth] = useState(
    document?.getElementById('stepperContainer')?.scrollWidth
  );

  const handleParentResize = () => {
    setStepperParentWidth(
      document?.getElementById('stepperParentContainer')?.scrollWidth
    );
  };

  useEffect(() => {
    window.addEventListener('resize', handleParentResize);

    return () => {
      window.removeEventListener('resize', handleParentResize);
    };
  }, []);

  useEffect(() => {
    setStepperParentWidth(
      document?.getElementById('stepperParentContainer')?.scrollWidth
    );

    setStepperWidth(document?.getElementById('stepperContainer')?.scrollWidth);
  }, [stepperParentWidth, stepperWidth]);

  const onWheel = (e: any) => {
    e.preventDefault();
    const containerScrollPosition = stepperContainer?.scrollLeft;
    stepperContainer?.scrollTo({
      top: 0,
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      left: containerScrollPosition + e.deltaY,
    });
  };

  const scrollLeft = () => {
    const scrollElement = document?.getElementById('stepperContainer');
    if (scrollElement) {
      scrollElement.scrollLeft -= 1000;
      setScrollingLeft(scrollElement.scrollLeft);
    }
  };

  const scrollRight = () => {
    const scrollElement = document?.getElementById('stepperContainer');
    if (scrollElement) {
      scrollElement.scrollLeft += 1000;
      setScrollingLeft(scrollElement.scrollLeft);
    }
  };

  return (
    <div id="stepperParentContainer" className={styles.stepperParentContainer}>
      {!wrap &&
        stepperWidth &&
        stepperParentWidth &&
        stepperWidth > stepperParentWidth && (
          <ATMIcon
            className={scrollingLeft > 0 ? styles.scrollLeft : styles.hidden}
            id="btn-left"
            name="angle left"
            size="big"
            onClick={() => {
              scrollLeft();
            }}
          />
        )}
      <Step.Group
        className={
          // eslint-disable-next-line no-nested-ternary
          !vertical
            ? wrap
              ? styles.stepperWrapHeader
              : styles.stepperScrollHeader
            : ''
        }
        id="stepperContainer"
        onWheel={onWheel}
        vertical={vertical}
      >
        {stepperData.map((item: any, index: number) => (
          <Step
            key={index}
            link={item.link}
            active={item.key === activeItem}
            disabled={item.disabled}
            completed={item.completed}
            className={styles.stepperText}
            onClick={(event) => {
              handleStepperClick(event, index, item.key);
            }}
          >
            {item.icon && (
              <ATMIcon
                name={item.icon}
                className={
                  // eslint-disable-next-line no-nested-ternary
                  item.key === activeItem
                    ? item.icon === 'square'
                      ? styles.stepperActiveRotateIcon
                      : styles.stepperActiveIcon
                    : item.icon === 'square'
                    ? styles.stepperRotateIcon
                    : styles.stepperIcon
                }
                color={item.color}
                size="small"
              />
            )}
            <Step.Content>
              {item.title && (
                <Step.Title
                  className={
                    item.key === activeItem
                      ? styles.stepperActiveTitle
                      : styles.stepperTitle
                  }
                >
                  {truncateText ? (
                    <ATMPopover
                      className={styles.delay}
                      trigger={
                        <div className={styles.stepperWrapText}>
                          {letterCaseWord(item.title)}{' '}
                        </div>
                      }
                      content={
                        item.popup ? item.popup : letterCaseWord(item.title)
                      }
                      size="mini"
                    />
                  ) : (
                    letterCaseWord(item.title)
                  )}
                </Step.Title>
              )}
              {item.description && (
                <Step.Description>{item.description}</Step.Description>
              )}
            </Step.Content>
          </Step>
        ))}
      </Step.Group>
      {!wrap &&
        stepperWidth &&
        stepperParentWidth &&
        stepperWidth > stepperParentWidth && (
          <ATMIcon
            className={scrollingLeft === 0 ? styles.scrollRight : styles.hidden}
            id="btn-right"
            name="angle right"
            size="big"
            onClick={() => {
              scrollRight();
            }}
          />
        )}
    </div>
  );
};
