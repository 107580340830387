import React from 'react';
import {
  Accordion,
  AccordionProps,
  AccordionContentProps,
  AccordionAccordionProps,
} from 'semantic-ui-react';
import './ATMAccordion.module.scss';

export interface IATMAccordion extends React.FC<AccordionProps> {
  Title: typeof Accordion.Title;
  Accordion: typeof Accordion.Accordion;
  Content: typeof Accordion.Content;
}

const ATMAccordion: IATMAccordion = ({ children, ...props }) => (
  <Accordion {...props}>{children}</Accordion>
);

ATMAccordion.Title = Accordion.Title;
ATMAccordion.Accordion = Accordion.Accordion;
ATMAccordion.Content = Accordion.Content;

export type IATMAccordionProps = AccordionProps;
export type IATMAccordionAccordionProps = AccordionAccordionProps;
export type IATMAccordionContentProps = AccordionContentProps;

export { ATMAccordion };
