import React, { useState } from 'react';
import { ATMSegment } from '../../atoms/ATMSegment/ATMSegment.component';
import { ATMHeader } from '../../atoms/ATMHeader/ATMHeader.component';
import styles from './MOLInfoText.module.scss';

export type IMOLInfoProps = {
  header?: string | undefined;
  content?: string;
  transparent?: boolean | undefined;
  limit?: number;
};

const MOLInfoText: React.FC<IMOLInfoProps> = ({
  header,
  content,
  transparent = false,
  limit = 250,
}) => {
  const [viewMore, setView] = useState(false);
  const textTruncate = (str: any, length: number) => {
    const ending = '...';
    if (str.length > length) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return str.substring(0, length - ending.length) + ending;
    }
    return str;
  };

  return (
    <ATMSegment className={transparent ? styles.transparent : ' '}>
      <ATMHeader as="h3" className={styles.noMarginBtm}>
        {header}
      </ATMHeader>
      <div>
        <span>
          <p className={styles.infoContent}>
            {viewMore ? content : textTruncate(content, limit)}
          </p>
        </span>
        {content && content.length > limit && (
          <a
            href="#view"
            className="sempraSecondaryLink"
            onClick={(e) => {
              e.preventDefault();
              setView(!viewMore);
            }}
          >
            {viewMore ? 'View Less' : 'View More'}
          </a>
        )}
      </div>
    </ATMSegment>
  );
};

export { MOLInfoText };
