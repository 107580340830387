import React from 'react';

type IProps = {
  width?: number;
  height?: number;
  color?: string;
  logoName?: string;
};

export const ATMBrandLogo: React.FC<IProps> = ({
  height,
  width,
  color,
  logoName = 'sempra energy',
}) => {
  let h = 119.6;
  let w = 482.17334;

  if (width && height) {
    h = height;
    w = width;
  } else if (width) {
    const percent = width / w;

    w = width;
    h *= percent;
  } else if (height) {
    const percent = height / h;

    h = height;
    w *= percent;
  }

  const getLogo = () => {
    const sempraLogo = (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 352 72"
        style={{
          width,
          height: height ?? 'auto',
          fill: color ?? '#204394',
        }}
      >
        <defs>
          <clipPath id="b">
            <rect width="352" height="72" />
          </clipPath>
        </defs>
        <g id="a">
          <g transform="translate(-0.556 -0.65)">
            <path
              id="b"
              d="M227.14,57.51h9V44.43h7.59c10.53,0,17.34-5.9,17.34-15v-.12c0-9-6.44-14.79-16.41-14.79H227.14Zm16.91-21h-7.9V22.55h7.71c5.07,0,8.09,2.58,8.09,6.91v.12c.05,4.13-3.18,6.9-7.9,6.9Zm50.89,21h10.5L294.88,42.09l.36-.14c5.9-2.19,9.15-6.89,9.15-13.24v-.13a13.43,13.43,0,0,0-3.65-9.76c-2.84-2.84-7.16-4.34-12.5-4.34H268.59v43h9V43.56h8Zm-7.33-21.84h-10V22.55h9.84c5,0,7.83,2.38,7.83,6.53v.13c-.02,3.99-2.95,6.46-7.67,6.46Zm54.94,21.84h9.38L333.49,14.17H325L306.59,57.51h9.13l4-9.78h18.85Zm-7.17-17.66H322.9l6.24-15.23Zm-246.17,12a27.67,27.67,0,0,0,18.3,6.38c9.69,0,15.72-5.12,15.72-13.36v-.12c0-7.71-5.21-10.61-13.61-12.8C102,30.05,100,29.08,100,26v-.13c0-2.11,1.83-4.23,5.91-4.23a20,20,0,0,1,11.27,3.71l4.65-6.55a24.9,24.9,0,0,0-15.8-5c-8.81,0-15,5.34-15,13v.13c0,8.74,5.9,10.92,14.24,13.05,7.07,1.81,9,3.07,9,5.83v.13c0,2.76-2.59,4.54-6.59,4.54a20.32,20.32,0,0,1-13.19-4.84Zm42.36,5.62h32.62v-7.9H140.51V39.82h20.57V31.87H140.51V22.43h23.37V14.48H131.57Zm75.91,0h8.94v-43h-9.88L194.91,33.2,183.28,14.48h-9.89v43h8.82v-29l12.57,19.08,12.7-19.27Z"
            />
            <path
              id="b"
              d="M38.25,9.91c2.74,0,5,1.95,5,4.36s-2.23,4.35-5,4.35-5-1.95-5-4.35S35.51,9.91,38.25,9.91Zm29.32,8.86a10.55,10.55,0,0,0,.88-2.33c0-.07.07-.33,0-.42a.24.24,0,0,0-.33,0C47.91,31.25,27.56,11.79,29.59,24.4c.26,1.63,1.86,4.56,3.8,7.4a.26.26,0,0,1,0,.33.64.64,0,0,1-.28.18c-10.54,5.58-17.52,14-21.79,20.75A30.05,30.05,0,0,1,58,15.5l.06.08.09-.05a29.21,29.21,0,0,0,4.93-3l.12-.09-.1-.11A36,36,0,0,0,8.1,58.7c-1.32,2.62-2,4.49-2.27,5.05C4.92,66,5.71,66.66,6,66.8c.65.26,1.6,0,2.56-1.27C15.3,57,33.43,36,42.41,42c5.09,3.44,4.93,7.3,1.1,10.84a37.44,37.44,0,0,1-7.57,4.85c-3.49,1.65-5.37,2-5.09,3.21s1.74,1.14,7.61-.39c6.75-1.75,21.43-6.8,15.3-19.06-3-6-13.14-10.87-13.14-11s.3-.1.3-.1c11.9-.89,18.64-3.8,22.46-6.74A30,30,0,0,1,19.15,60.88L19,60.8l-.08.12c-1,1.6-2,3.23-2.92,4.85l-.06.12.11.07A36,36,0,0,0,67.57,18.77Z"
            />
            <path id="b" d="M342.77,15.07H341v-.9h4.49v.9h-1.76v4.62h-1Z" />
            <path
              id="b"
              d="M346.51,14.17h1l1.68,2.61,1.68-2.61h1v5.52h-1v-4l-1.74,2.61h0l-1.73-2.59v3.94h-.95Z"
            />
          </g>
        </g>
      </svg>
    );
    const sdgeLogo = (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 351.34 206.76"
        style={{ width, height: height ?? 'auto', fill: color ?? '#204394' }}
      >
        <path
          id="cls-1"
          d="M354.69,3.93l-2.24,7.2L340.83,52H308.22c-12,.11-23.57,9-28.2,20.27l1.41-5.09,12.26-42.44c4.52-11.63,15.72-20,27.66-20.83Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-2"
          d="M271.09,35a10.61,10.61,0,0,1,2.8,8.65l-5.16,18.28-19.15-.11c1.06-4.33,2.47-8.43,3.33-13a4,4,0,0,0-.43-2.21,13.41,13.41,0,0,0-13.34-3c-6.25,1.21-11.09,7.42-13.89,13l-2.15,5.21-11.63,40.44c-1.18,3.88-2.9,8.65-.1,12.3,4.73,5.76,13.23,5.65,19.58,3.76,1.72-.66,4-1,5-2.54,1.72-6.42,3.87-12.85,5.48-19.28l-.43-.22H227.09l4.73-17.06.31-.23c-.1-.1-.21-.1-.21-.33,10.33-.11,21.2,0,31.86,0l-.22,1.44-15,51.41c-15.39,4.1-35,7.43-50,.45-8.39-5.32-10.11-15.51-8.61-24.71l1.29-5.1L204.16,58c3.76-12,11-24.93,23.56-28.92a70.91,70.91,0,0,1,36.06,1A18.61,18.61,0,0,1,271.09,35Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-2"
          d="M110.1,51.9a9.64,9.64,0,0,1,2.26,9.2l-5.27,17.95c-6.67.33-13-.11-19.48,0,1-3.87,2.27-7.54,3.24-11.52a6.67,6.67,0,0,0-3.34-6c-4-2-9-.89-12.38,2a18.64,18.64,0,0,0-6.78,15,9.29,9.29,0,0,0,2.48,5.65c6.35,6,13.13,11.41,19.69,17.29A18.21,18.21,0,0,1,95,117.28c-1.4,13.08-8.61,26.49-21,32-11.51,5.2-26.68,7.09-38.63,1.65a17.47,17.47,0,0,1-8.82-17.28c1.19-6,3.33-11.63,4.73-17.4,5.92-.11,13.14,0,19.7,0-.76,5.77-6.35,13.63.22,18.39,4,3,9.68,1.12,13.44-1.1,5-3.54,7.64-9.2,8.83-14.74.64-6-5.81-8-8.94-11.85-6.77-7.2-18.07-12-18.5-23.16,0-11,4.3-21.17,11.08-29.59,11.51-11.85,31.42-12.85,46-7a20.06,20.06,0,0,1,7,4.65Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-2"
          d="M165.31,46.37c7.75.66,15.82,3.1,19.48,11,3.55,7.21,1.61,16.07-.32,23.5l-15.82,53.84a25.48,25.48,0,0,1-23.35,16.41H99.56l.32-1.33,12.81-44.32L129.9,46.26l35.41.11Zm-19.48,17-.21.22-20.24,69.25.22.34c5.92.1,11.73.32,18.08,0a10.52,10.52,0,0,0,5.49-3.44c2.91-4.87,3.65-10.41,5.38-15.73l11.08-37.68c.65-2.43,1.83-5.21.75-7.75a8.09,8.09,0,0,0-5.8-5l-14.75-.22Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-2"
          d="M330.6,78.61c-1.4,5.76-3.22,11.64-5,17.51-10.54.11-20.66.22-30.88.11-2.58,8.09-5,16.18-7.21,24.37l.43.22h28.73l.11.24c-1.83,6.08-3.55,12.39-5.49,18.6l-29.16.11c-3,9.31-5.6,18.4-8.18,27.71l.1.22c10.87.22,21.63,0,32.61.22l-5.06,18-4,.22h-50L248,184l15.92-55.07L278.2,78.73l.21-.12H330.6Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-1"
          d="M232,189.64c-4.2,10.64-14.64,19.39-25.83,20.61l-6.56.44L3.35,210.52l14.21-47.7c9.36-.33,199.08,0,199.08,0,12.37-.22,24.32-9.2,29-21L232,189.64Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-2"
          d="M329.4,168.22l-.29,1.59h-4.55l-2.5,14.13h-1.75l2.49-14.13h-4.6l.29-1.59Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          id="cls-2"
          d="M346.06,168.22l-2.79,15.72h-1.68l1.88-10.65.3-1.68c-.27.61-.9,1.77-1.4,2.61l-5.94,10.12-2.3-10.12c-.17-.81-.42-2-.47-2.61l-.29,1.68-1.88,10.65H329.8l2.79-15.72h1.91l2,9.41c.21,1,.52,2.4.59,3.05.31-.67,1.14-2.15,1.66-3.05l5.34-9.41Z"
          transform="translate(-3.35 -3.93)"
        />
      </svg>
    );
    const socalLogo = (
      <svg
        viewBox="0 0 185.66666 75.653336"
        height="40.653336"
        width="80.66666"
        id="svg2"
        version="1.1"
        style={{ width, height: height ?? 'auto', fill: color ?? '#204394' }}
      >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,75.653333)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <path
              id="path14"
              style={{ fill: '#ffffff' }}
              d="M 0,567.422 H 305.426 V 9.35547 h -92.988 c 17.488,58.63283 16.523,130.51553 4.941,191.38653 -4.805,25.238 -10.09,50.512 -16.934,75.281 l -0.332,0.61 -0.976,-2.934 C 176.898,191.895 161.801,93.6914 187.457,9.35547 H 161.703 C 136.617,44.9805 118.48,84.6953 111.449,127.766 c -2.988,23.699 -3.398,50.769 -0.965,74.976 7.996,65.641 29.883,125.555 54.102,184.446 10.918,26.5 24.727,50.625 36.766,77 l 0.078,78.046 C 152.133,456.676 107.055,367.09 81.9102,270.859 66.2148,210.648 61,137.488 81.0898,77.9102 88.9688,54.1797 99.7695,30.7813 113.59,9.35547 H 0 V 567.422"
            />
            <path
              id="path16"
              style={{ fill: '#ffffff' }}
              d="m 486.188,210.938 v 6.406 c 0,21.308 -2.11,30.133 -10.313,40.746 -9.445,11.59 -27.668,18.238 -51.113,18.238 -39.817,0 -62.969,-21.558 -62.969,-58.664 0,-15.203 4.871,-27.391 13.992,-35.926 7.61,-6.679 21.016,-15.215 35.586,-22.793 30.129,-15.547 35.285,-20.683 35.285,-37.109 0,-15.203 -7.91,-23.6837 -21.613,-23.6837 -16.688,0 -21.277,7.5237 -21.883,35.4997 h -43.176 c 0,-24.875 1.536,-32.773 7.305,-42.8825 8.496,-14.8632 29.211,-23.0742 55.938,-23.0742 21.906,0 40.168,5.1953 50.519,14.2852 9.734,8.789 15.527,25.5395 15.527,44.7385 0,26.797 -9.757,41.328 -37.101,55.976 l -17.988,9.082 c -20.664,10.957 -28.868,20.403 -28.868,33.477 0,12.812 7.286,20.664 19.172,20.664 14.258,0 18.535,-7.852 18.848,-34.98 h 42.852"
            />
            <path
              id="path18"
              style={{ fill: '#ffffff' }}
              d="m 618.949,146.152 c 0,14.922 -1.219,29.192 -3.301,37.403 -4.609,19.492 -21.648,28.925 -52.664,28.925 -18.261,0 -30.714,-3.667 -39.211,-11.25 -9.441,-8.535 -13.406,-23.769 -13.406,-51.992 l 0.332,-4.277 0.262,-17.371 c 0.324,-28.0002 2.453,-37.0822 10.969,-46.2228 8.222,-8.4766 21.855,-12.5195 42.265,-12.5195 18.602,0 30.145,2.7734 40.145,10.7031 10.351,8.2422 13.976,18.8476 14.297,44.4332 z m -69.031,-0.261 v 16.714 c 0,18.528 4.258,25.84 14.277,25.84 13.407,0 15.496,-6.98 15.496,-52.066 v -9.082 l -0.593,-10.949 c -0.586,-18.2035 -3.926,-23.6917 -14.571,-23.6917 -11.574,0 -14.609,6.6679 -14.609,31.9337 v 2.129 19.172"
            />
            <path
              id="path20"
              style={{ fill: '#ffffff' }}
              d="m 730.855,138.262 c -1.183,-32.227 -5.46,-39.512 -22.832,-39.512 -8.8,0 -15.48,3.914 -17.941,10.918 -3.027,7.898 -4.559,26.777 -4.559,60.527 0,21.016 0.606,36.2 1.532,49.293 1.203,17.621 8.222,25.239 22.5,25.239 14.004,0 20.41,-7.618 20.656,-24.922 0.332,-2.75 0.332,-6.703 0.644,-11.883 h 41.942 v 11.566 c 0,9.731 -1.172,17.938 -2.996,24.969 -6.106,19.777 -28.633,31.906 -59.629,31.906 -20.684,0 -37.422,-5.179 -48.965,-14.875 -14.902,-12.832 -18.574,-30.148 -18.574,-90.093 0,-23.133 0.918,-44.676 2.148,-56.864 3.653,-31.3279 24.637,-46.8357 63.555,-46.8357 22.812,0 41.648,5.4883 51.414,15.1953 9.699,9.4922 12.766,22.2264 13.047,55.3714 h -41.942"
            />
            <path
              id="path22"
              style={{ fill: '#ffffff' }}
              d="m 833.473,169.91 c 0.312,17.641 2.097,21.301 11.855,21.301 10.027,0 12.469,-4.277 12.469,-21.301 v -11.57 c -6.387,0.312 -9.442,0.312 -12.774,0.312 -39.246,0 -52.937,-12.5 -52.937,-48.984 0,-27.9805 10.371,-40.7539 31.98,-40.7539 13.704,0 23.106,4.5429 33.731,17.3359 l 0.586,-15.5195 h 38.945 c -1.211,11.2812 -1.523,15.832 -1.523,21.3008 v 9.7457 56.563 c 0,25.879 -1.512,33.144 -8.504,41.367 -6.692,8.215 -21.613,12.758 -40.774,12.758 -15.214,0 -27.988,-2.695 -37.41,-8.207 -10.637,-6.035 -13.39,-13.047 -13.672,-34.348 z m 24.324,-32.508 V 120 c 0,-20.1055 -4.246,-27.6172 -15.234,-27.6172 -9.09,0 -12.469,5.3906 -12.469,20.3122 0,19.825 4.277,24.707 21.316,24.707 h 6.387"
            />
            <path
              id="path24"
              style={{ fill: '#ffffff' }}
              d="M 960.016,273.301 H 921.383 V 70.7305 h 38.633 V 273.301"
            />
            <path
              id="path26"
              style={{ fill: '#ffffff' }}
              d="m 1052.53,178.184 v -31.707 h 21.92 v -46.196 c -8.84,-1.2654 -12.82,-1.5115 -19.77,-1.5115 -13.99,0 -20.71,3.6135 -24.06,13.0465 -2.72,7.286 -3.36,18.848 -3.36,59.036 0,10.054 0.32,18.25 0.64,25.515 l 0.62,16.438 c 0.89,25.261 6.98,33.75 24.32,33.75 14.9,0 21.27,-7.297 21.27,-24.653 l 0.34,-10.293 h 41.92 c -0.25,20.071 -0.57,21.567 -2.7,30.996 -4.9,22.215 -26.12,33.813 -62.65,33.813 -10.33,0 -20.1,-1.227 -28.33,-3.344 -18.22,-5.5 -32.217,-22.246 -35.264,-42.972 -1.817,-13.934 -3.367,-41.918 -3.367,-66.235 0,-29.211 0.625,-43.203 3.367,-56.015 4.855,-26.4965 27.094,-40.1762 64.184,-40.1762 21.87,0 41.99,3.0469 64.76,8.8476 V 178.184 h -63.84"
            />
            <path
              id="path28"
              style={{ fill: '#ffffff' }}
              d="m 1181.28,169.922 c 0.32,17.644 2.17,21.25 11.92,21.25 10.01,0 12.44,-4.199 12.44,-21.25 v -11.524 c -6.43,0.254 -9.45,0.254 -12.77,0.254 -39.27,0 -52.97,-12.5 -52.97,-48.984 0,-27.9805 10.39,-40.7227 32,-40.7227 13.68,0 23.08,4.5781 33.74,17.3047 l 0.61,-15.5195 h 38.93 c -1.17,11.25 -1.53,15.8398 -1.53,21.332 v 9.7145 56.621 c 0,25.84 -1.5,33.118 -8.52,41.309 -6.68,8.258 -21.61,12.793 -40.72,12.793 -15.22,0 -27.99,-2.766 -37.45,-8.203 -10.62,-6.094 -13.4,-13.125 -13.69,-34.375 z m 24.36,-32.559 v -17.324 c 0,-20.0898 -4.28,-27.7148 -15.21,-27.7148 -9.15,0 -12.47,5.4961 -12.47,20.3708 0,19.825 4.22,24.668 21.25,24.668 h 6.43"
            />
            <path
              id="path30"
              style={{ fill: '#ffffff' }}
              d="m 1365.06,169.297 c 0,16.125 -1.8,22.558 -8.49,30.078 -7.91,8.84 -20.65,13.125 -39.54,13.125 -32.55,0 -51.11,-14.297 -51.11,-39.238 0,-16.114 7.86,-26.426 28.02,-36.504 l 10.29,-4.863 c 21.29,-10.665 25.57,-14.922 25.57,-25.293 0,-8.7895 -5.44,-14.8832 -13.68,-14.8832 -9.46,0 -12.8,5.2148 -13.09,18.5942 0,1.503 0,3.664 -0.28,6.375 h -37.77 v -3.7 c 0,-30.4372 16.78,-44.0427 52.96,-44.0427 31.28,0 50.48,15.7617 50.48,42.2847 0,10.293 -3.64,19.688 -10.3,26.458 -6.09,6.074 -14.34,11.218 -29.52,18.835 l -8.5,4.266 c -12.19,6.086 -16.46,10.949 -16.46,18.254 0,7.93 4.27,12.129 11.86,12.129 8.2,0 11.87,-4.512 11.87,-15.469 0,-1.523 0.33,-3.633 0.6,-6.406 h 37.09"
            />
          </g>
        </g>
      </svg>
    );

    const sempraExtrenalLogo = (
      <svg
        viewBox="0 0 391.66666 75.653336"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: w, height: h }}
      >
        <path
          id="Wordmark"
          style={{ fill: '#001689' }}
          d="M227.14,57.51h9V44.43h7.59c10.53,0,17.34-5.9,17.34-15v-.12c0-9-6.44-14.79-16.41-14.79H227.14Zm16.91-21h-7.9V22.55h7.71c5.07,0,8.09,2.58,8.09,6.91v.12C252,33.71,248.77,36.48,244.05,36.48Zm50.89,21h10.5L294.88,42.09l.36-.14c5.9-2.19,9.15-6.89,9.15-13.24v-.13a13.43,13.43,0,0,0-3.65-9.76c-2.84-2.84-7.16-4.34-12.5-4.34H268.59v43h9V43.56h8Zm-7.33-21.84h-10V22.55h9.84c5,0,7.83,2.38,7.83,6.53v.13C295.26,33.2,292.33,35.67,287.61,35.67Zm54.94,21.84h9.38L333.49,14.17H325L306.59,57.51h9.13l4-9.78h18.85Zm-7.17-17.66H322.9l6.24-15.23Zm-246.17,12a27.67,27.67,0,0,0,18.3,6.38c9.69,0,15.72-5.12,15.72-13.36v-.12c0-7.71-5.21-10.61-13.61-12.8C102,30.05,100,29.08,100,26v-.13c0-2.11,1.83-4.23,5.91-4.23a20,20,0,0,1,11.27,3.71l4.65-6.55a24.9,24.9,0,0,0-15.8-5c-8.81,0-15,5.34-15,13v.13c0,8.74,5.9,10.92,14.24,13.05,7.07,1.81,9,3.07,9,5.83v.13c0,2.76-2.59,4.54-6.59,4.54a20.32,20.32,0,0,1-13.19-4.84Zm42.36,5.62h32.62V49.57H140.51V39.82h20.57V31.87H140.51V22.43h23.37V14.48H131.57Zm75.91,0h8.94v-43h-9.88L194.91,33.2,183.28,14.48h-9.89v43h8.82v-29l12.57,19.08,12.7-19.27Z"
        />
        <path
          id="Symbol"
          style={{ fill: '#ea0029' }}
          d="M38.25,9.91c2.74,0,5,1.95,5,4.36s-2.23,4.35-5,4.35-5-1.95-5-4.35S35.51,9.91,38.25,9.91Zm29.32,8.86a10.55,10.55,0,0,0,.88-2.33c0-.07.07-.33,0-.42a.24.24,0,0,0-.33,0c-20.21,15.23-40.56-4.23-38.53,8.38.26,1.63,1.86,4.56,3.8,7.4a.26.26,0,0,1,0,.33.64.64,0,0,1-.28.18c-10.54,5.58-17.52,14-21.79,20.75A30.05,30.05,0,0,1,58,15.5l.06.08.09-.05a29.21,29.21,0,0,0,4.93-3l.12-.09-.1-.11a36,36,0,0,0-55,46.37c-1.32,2.62-2,4.49-2.27,5.05C4.92,66,5.71,66.66,6,66.8c.65.26,1.6,0,2.56-1.27C15.3,57,33.43,36,42.41,42c5.09,3.44,4.93,7.3,1.1,10.84a37.44,37.44,0,0,1-7.57,4.85c-3.49,1.65-5.37,2-5.09,3.21s1.74,1.14,7.61-.39c6.75-1.75,21.43-6.8,15.3-19.06-3-6-13.14-10.87-13.14-11s.3-.1.3-.1c11.9-.89,18.64-3.8,22.46-6.74A30,30,0,0,1,19.15,60.88L19,60.8l-.08.12c-1,1.6-2,3.23-2.92,4.85l-.06.12.11.07A36,36,0,0,0,67.57,18.77Z"
        />
        <path
          style={{ fill: '#001689' }}
          d="M342.77,15.07H341v-.9h4.49v.9h-1.76v4.62h-1Z"
        />
        <path
          style={{ fill: '#001689' }}
          d="M346.51,14.17h1l1.68,2.61,1.68-2.61h1v5.52h-1v-4l-1.74,2.61h0l-1.73-2.59v3.94h-.95Z"
        />
      </svg>
    );
    const sdgeExternal = (
      <svg
        viewBox="0 0 351.34 206.76"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: ' 53px', height: '30px' }}
      >
        <path
          style={{ fill: '#ca003d' }}
          d="M354.69,3.93l-2.24,7.2L340.83,52H308.22c-12,.11-23.57,9-28.2,20.27l1.41-5.09,12.26-42.44c4.52-11.63,15.72-20,27.66-20.83Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M271.09,35a10.61,10.61,0,0,1,2.8,8.65l-5.16,18.28-19.15-.11c1.06-4.33,2.47-8.43,3.33-13a4,4,0,0,0-.43-2.21,13.41,13.41,0,0,0-13.34-3c-6.25,1.21-11.09,7.42-13.89,13l-2.15,5.21-11.63,40.44c-1.18,3.88-2.9,8.65-.1,12.3,4.73,5.76,13.23,5.65,19.58,3.76,1.72-.66,4-1,5-2.54,1.72-6.42,3.87-12.85,5.48-19.28l-.43-.22H227.09l4.73-17.06.31-.23c-.1-.1-.21-.1-.21-.33,10.33-.11,21.2,0,31.86,0l-.22,1.44-15,51.41c-15.39,4.1-35,7.43-50,.45-8.39-5.32-10.11-15.51-8.61-24.71l1.29-5.1L204.16,58c3.76-12,11-24.93,23.56-28.92a70.91,70.91,0,0,1,36.06,1A18.61,18.61,0,0,1,271.09,35Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M110.1,51.9a9.64,9.64,0,0,1,2.26,9.2l-5.27,17.95c-6.67.33-13-.11-19.48,0,1-3.87,2.27-7.54,3.24-11.52a6.67,6.67,0,0,0-3.34-6c-4-2-9-.89-12.38,2a18.64,18.64,0,0,0-6.78,15,9.29,9.29,0,0,0,2.48,5.65c6.35,6,13.13,11.41,19.69,17.29A18.21,18.21,0,0,1,95,117.28c-1.4,13.08-8.61,26.49-21,32-11.51,5.2-26.68,7.09-38.63,1.65a17.47,17.47,0,0,1-8.82-17.28c1.19-6,3.33-11.63,4.73-17.4,5.92-.11,13.14,0,19.7,0-.76,5.77-6.35,13.63.22,18.39,4,3,9.68,1.12,13.44-1.1,5-3.54,7.64-9.2,8.83-14.74.64-6-5.81-8-8.94-11.85-6.77-7.2-18.07-12-18.5-23.16,0-11,4.3-21.17,11.08-29.59,11.51-11.85,31.42-12.85,46-7a20.06,20.06,0,0,1,7,4.65Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M165.31,46.37c7.75.66,15.82,3.1,19.48,11,3.55,7.21,1.61,16.07-.32,23.5l-15.82,53.84a25.48,25.48,0,0,1-23.35,16.41H99.56l.32-1.33,12.81-44.32L129.9,46.26l35.41.11Zm-19.48,17-.21.22-20.24,69.25.22.34c5.92.1,11.73.32,18.08,0a10.52,10.52,0,0,0,5.49-3.44c2.91-4.87,3.65-10.41,5.38-15.73l11.08-37.68c.65-2.43,1.83-5.21.75-7.75a8.09,8.09,0,0,0-5.8-5l-14.75-.22Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M330.6,78.61c-1.4,5.76-3.22,11.64-5,17.51-10.54.11-20.66.22-30.88.11-2.58,8.09-5,16.18-7.21,24.37l.43.22h28.73l.11.24c-1.83,6.08-3.55,12.39-5.49,18.6l-29.16.11c-3,9.31-5.6,18.4-8.18,27.71l.1.22c10.87.22,21.63,0,32.61.22l-5.06,18-4,.22h-50L248,184l15.92-55.07L278.2,78.73l.21-.12H330.6Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#ca003d' }}
          d="M232,189.64c-4.2,10.64-14.64,19.39-25.83,20.61l-6.56.44L3.35,210.52l14.21-47.7c9.36-.33,199.08,0,199.08,0,12.37-.22,24.32-9.2,29-21L232,189.64Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M329.4,168.22l-.29,1.59h-4.55l-2.5,14.13h-1.75l2.49-14.13h-4.6l.29-1.59Z"
          transform="translate(-3.35 -3.93)"
        />
        <path
          style={{ fill: '#231f20' }}
          d="M346.06,168.22l-2.79,15.72h-1.68l1.88-10.65.3-1.68c-.27.61-.9,1.77-1.4,2.61l-5.94,10.12-2.3-10.12c-.17-.81-.42-2-.47-2.61l-.29,1.68-1.88,10.65H329.8l2.79-15.72h1.91l2,9.41c.21,1,.52,2.4.59,3.05.31-.67,1.14-2.15,1.66-3.05l5.34-9.41Z"
          transform="translate(-3.35 -3.93)"
        />
      </svg>
    );
    const socalExternal = (
      <svg
        viewBox="0 0 237.71 93.35"
        height="119.6"
        width="482.17334"
        xmlSpace="preserve"
        id="svg2"
        version="1.1"
        style={{ width: ' 76px', height: '35px' }}
      >
        <path
          style={{ fill: '#004b90' }}
          d="M2.22,2H53.31V95.36H37.76c2.92-9.81,2.76-21.83.82-32-.8-4.22-1.68-8.45-2.83-12.59l0-.1-.17.49c-3.72,13.68-6.24,30.11-2,44.21H29.27a47.85,47.85,0,0,1-8.4-19.8A57.72,57.72,0,0,1,20.71,63c1.33-11,5-21,9.05-30.86,1.82-4.43,4.13-8.46,6.14-12.87l0-13.06c-8.25,14.32-15.79,29.3-20,45.39-2.63,10.08-3.5,22.31-.14,32.28a50.69,50.69,0,0,0,5.43,11.46h-19Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M83.55,61.65V60.58c0-3.57-.36-5-1.73-6.82-1.58-1.94-4.63-3-8.55-3-6.66,0-10.53,3.61-10.53,9.81a7.94,7.94,0,0,0,2.34,6,32.47,32.47,0,0,0,6,3.81c5,2.6,5.91,3.46,5.91,6.21,0,2.54-1.33,4-3.62,4-2.79,0-3.56-1.26-3.66-5.93H62.44c0,4.15.25,5.47,1.22,7.16,1.42,2.49,4.89,3.86,9.36,3.86,3.66,0,6.72-.86,8.45-2.38s2.59-4.28,2.59-7.48c0-4.49-1.63-6.92-6.2-9.37l-3-1.52C71.39,63,70,61.44,70,59.25s1.22-3.46,3.21-3.46c2.38,0,3.1,1.32,3.15,5.86Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M94.21,72.53v-2.8c0-3.1.71-4.32,2.39-4.32,2.24,0,2.59,1.17,2.59,8.71v1.51l-.1,1.84c-.1,3-.66,4-2.44,4-1.93,0-2.44-1.12-2.44-5.34V72.53Zm11.55,0a29,29,0,0,0-.56-6.26c-.77-3.26-3.62-4.83-8.81-4.83-3,0-5.13.61-6.55,1.88-1.58,1.43-2.25,4-2.25,8.7l.06.71,0,2.91c.06,4.68.41,6.2,1.84,7.73s3.65,2.09,7.07,2.09c3.11,0,5-.46,6.71-1.79s2.34-3.15,2.39-7.43Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M124.47,73.8c-.2,5.4-.91,6.61-3.82,6.61a2.92,2.92,0,0,1-3-1.83c-.5-1.31-.76-4.47-.76-10.12,0-3.51.1-6,.26-8.24.19-2.95,1.37-4.23,3.76-4.23s3.41,1.28,3.45,4.17c.06.46.06,1.12.11,2h7V60.22A16.79,16.79,0,0,0,131,56c-1-3.31-4.79-5.34-10-5.34-3.46,0-6.26.87-8.19,2.49-2.49,2.15-3.1,5-3.1,15.07,0,3.87.15,7.48.35,9.51.62,5.24,4.13,7.83,10.63,7.83,3.82,0,7-.91,8.6-2.54s2.14-3.71,2.19-9.26Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M145.7,74v2.91c0,3.36-.7,4.62-2.54,4.62-1.52,0-2.09-.91-2.09-3.4,0-3.32.72-4.13,3.57-4.13Zm-4.06-5.44c.05-3,.35-3.56,2-3.56s2.08.71,2.08,3.56v1.93c-1.06,0-1.58,0-2.13,0-6.57,0-8.86,2.09-8.86,8.19,0,4.69,1.74,6.82,5.35,6.82,2.29,0,3.87-.76,5.64-2.9l.1,2.6h6.52a29.45,29.45,0,0,1-.26-3.56V70.44c0-4.33-.25-5.54-1.42-6.92s-3.62-2.13-6.82-2.13a12.52,12.52,0,0,0-6.26,1.37c-1.78,1-2.24,2.19-2.28,5.75Z"
          transform="translate(-2.22 -2.02)"
        />
        <rect
          style={{ fill: '#004b90' }}
          x="154.12"
          y="49.2"
          width="6.46"
          height="33.88"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M178.28,67.13v5.3h3.66v7.73a19.63,19.63,0,0,1-3.3.25c-2.34,0-3.47-.61-4-2.19-.45-1.21-.56-3.15-.56-9.87,0-1.68,0-3,.11-4.27l.1-2.75c.15-4.22,1.17-5.64,4.07-5.64,2.49,0,3.56,1.22,3.56,4.12l.05,1.72h7a18.26,18.26,0,0,0-.46-5.18c-.81-3.71-4.37-5.65-10.48-5.65a19.5,19.5,0,0,0-4.73.55,8.72,8.72,0,0,0-5.9,7.19,101.52,101.52,0,0,0-.57,11.08c0,4.89.11,7.23.57,9.37.81,4.43,4.53,6.72,10.73,6.72A42.57,42.57,0,0,0,189,84.13v-17Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M203.89,74v2.9c0,3.36-.72,4.63-2.55,4.63-1.53,0-2.08-.91-2.08-3.4,0-3.32.7-4.13,3.55-4.13Zm-4.08-5.44c.06-3,.37-3.56,2-3.56s2.08.71,2.08,3.56v1.92c-1.08,0-1.58,0-2.14,0-6.57,0-8.86,2.09-8.86,8.19,0,4.69,1.74,6.82,5.35,6.82,2.29,0,3.86-.77,5.65-2.9l.1,2.6h6.51a30.92,30.92,0,0,1-.25-3.57V70.43c0-4.32-.26-5.53-1.43-6.91s-3.62-2.14-6.81-2.14a12.66,12.66,0,0,0-6.27,1.38c-1.77,1-2.24,2.19-2.29,5.75Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M230.55,68.61c0-2.69-.3-3.77-1.42-5-1.32-1.48-3.45-2.2-6.61-2.2-5.45,0-8.55,2.4-8.55,6.57,0,2.69,1.32,4.42,4.69,6.1l1.72.82c3.56,1.78,4.28,2.5,4.28,4.23a2.27,2.27,0,0,1-2.29,2.49c-1.58,0-2.14-.88-2.19-3.11a9.15,9.15,0,0,0-.05-1.07h-6.32V78c0,5.09,2.81,7.37,8.86,7.37,5.23,0,8.45-2.64,8.45-7.07a6.21,6.21,0,0,0-1.73-4.43,18.37,18.37,0,0,0-4.94-3.15L223,70c-2-1-2.75-1.83-2.75-3a1.8,1.8,0,0,1,2-2c1.37,0,2,.76,2,2.59,0,.25.05.6.1,1.07Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M235.59,80.88h-1.22v3.58h-.63V80.88h-1.22v-.62h3.07Z"
          transform="translate(-2.22 -2.02)"
        />
        <path
          style={{ fill: '#004b90' }}
          d="M239.94,84.46h-.62V82.24c0-.25,0-.62,0-.74,0,.12-.17.42-.27.66l-1,2.37-1-2.37c-.11-.24-.23-.54-.27-.66,0,.12,0,.49,0,.74v2.22h-.6v-4.2h.65l.94,2.24c.1.24.24.55.29.72.06-.17.18-.47.28-.72l.93-2.24h.67Z"
          transform="translate(-2.22 -2.02)"
        />
      </svg>
    );
    const socalGasLogo = (
      <svg
        viewBox="0 0 185.66666 75.653336"
        height="40.653336"
        width="80.66666"
        id="svg2"
        version="1.1"
        style={{ width: '70px', height: 'auto', maxWidth: '250px' }}
      >
        <g transform="matrix(1.3333333,0,0,-1.3333333,0,75.653333)" id="g10">
          <g transform="scale(0.1)" id="g12">
            <path
              id="path14"
              style={{ fill: '#ffffff' }}
              d="M 0,567.422 H 305.426 V 9.35547 h -92.988 c 17.488,58.63283 16.523,130.51553 4.941,191.38653 -4.805,25.238 -10.09,50.512 -16.934,75.281 l -0.332,0.61 -0.976,-2.934 C 176.898,191.895 161.801,93.6914 187.457,9.35547 H 161.703 C 136.617,44.9805 118.48,84.6953 111.449,127.766 c -2.988,23.699 -3.398,50.769 -0.965,74.976 7.996,65.641 29.883,125.555 54.102,184.446 10.918,26.5 24.727,50.625 36.766,77 l 0.078,78.046 C 152.133,456.676 107.055,367.09 81.9102,270.859 66.2148,210.648 61,137.488 81.0898,77.9102 88.9688,54.1797 99.7695,30.7813 113.59,9.35547 H 0 V 567.422"
            />
            <path
              id="path16"
              style={{ fill: '#ffffff' }}
              d="m 486.188,210.938 v 6.406 c 0,21.308 -2.11,30.133 -10.313,40.746 -9.445,11.59 -27.668,18.238 -51.113,18.238 -39.817,0 -62.969,-21.558 -62.969,-58.664 0,-15.203 4.871,-27.391 13.992,-35.926 7.61,-6.679 21.016,-15.215 35.586,-22.793 30.129,-15.547 35.285,-20.683 35.285,-37.109 0,-15.203 -7.91,-23.6837 -21.613,-23.6837 -16.688,0 -21.277,7.5237 -21.883,35.4997 h -43.176 c 0,-24.875 1.536,-32.773 7.305,-42.8825 8.496,-14.8632 29.211,-23.0742 55.938,-23.0742 21.906,0 40.168,5.1953 50.519,14.2852 9.734,8.789 15.527,25.5395 15.527,44.7385 0,26.797 -9.757,41.328 -37.101,55.976 l -17.988,9.082 c -20.664,10.957 -28.868,20.403 -28.868,33.477 0,12.812 7.286,20.664 19.172,20.664 14.258,0 18.535,-7.852 18.848,-34.98 h 42.852"
            />
            <path
              id="path18"
              style={{ fill: '#ffffff' }}
              d="m 618.949,146.152 c 0,14.922 -1.219,29.192 -3.301,37.403 -4.609,19.492 -21.648,28.925 -52.664,28.925 -18.261,0 -30.714,-3.667 -39.211,-11.25 -9.441,-8.535 -13.406,-23.769 -13.406,-51.992 l 0.332,-4.277 0.262,-17.371 c 0.324,-28.0002 2.453,-37.0822 10.969,-46.2228 8.222,-8.4766 21.855,-12.5195 42.265,-12.5195 18.602,0 30.145,2.7734 40.145,10.7031 10.351,8.2422 13.976,18.8476 14.297,44.4332 z m -69.031,-0.261 v 16.714 c 0,18.528 4.258,25.84 14.277,25.84 13.407,0 15.496,-6.98 15.496,-52.066 v -9.082 l -0.593,-10.949 c -0.586,-18.2035 -3.926,-23.6917 -14.571,-23.6917 -11.574,0 -14.609,6.6679 -14.609,31.9337 v 2.129 19.172"
            />
            <path
              id="path20"
              style={{ fill: '#ffffff' }}
              d="m 730.855,138.262 c -1.183,-32.227 -5.46,-39.512 -22.832,-39.512 -8.8,0 -15.48,3.914 -17.941,10.918 -3.027,7.898 -4.559,26.777 -4.559,60.527 0,21.016 0.606,36.2 1.532,49.293 1.203,17.621 8.222,25.239 22.5,25.239 14.004,0 20.41,-7.618 20.656,-24.922 0.332,-2.75 0.332,-6.703 0.644,-11.883 h 41.942 v 11.566 c 0,9.731 -1.172,17.938 -2.996,24.969 -6.106,19.777 -28.633,31.906 -59.629,31.906 -20.684,0 -37.422,-5.179 -48.965,-14.875 -14.902,-12.832 -18.574,-30.148 -18.574,-90.093 0,-23.133 0.918,-44.676 2.148,-56.864 3.653,-31.3279 24.637,-46.8357 63.555,-46.8357 22.812,0 41.648,5.4883 51.414,15.1953 9.699,9.4922 12.766,22.2264 13.047,55.3714 h -41.942"
            />
            <path
              id="path22"
              style={{ fill: '#ffffff' }}
              d="m 833.473,169.91 c 0.312,17.641 2.097,21.301 11.855,21.301 10.027,0 12.469,-4.277 12.469,-21.301 v -11.57 c -6.387,0.312 -9.442,0.312 -12.774,0.312 -39.246,0 -52.937,-12.5 -52.937,-48.984 0,-27.9805 10.371,-40.7539 31.98,-40.7539 13.704,0 23.106,4.5429 33.731,17.3359 l 0.586,-15.5195 h 38.945 c -1.211,11.2812 -1.523,15.832 -1.523,21.3008 v 9.7457 56.563 c 0,25.879 -1.512,33.144 -8.504,41.367 -6.692,8.215 -21.613,12.758 -40.774,12.758 -15.214,0 -27.988,-2.695 -37.41,-8.207 -10.637,-6.035 -13.39,-13.047 -13.672,-34.348 z m 24.324,-32.508 V 120 c 0,-20.1055 -4.246,-27.6172 -15.234,-27.6172 -9.09,0 -12.469,5.3906 -12.469,20.3122 0,19.825 4.277,24.707 21.316,24.707 h 6.387"
            />
            <path
              id="path24"
              style={{ fill: '#ffffff' }}
              d="M 960.016,273.301 H 921.383 V 70.7305 h 38.633 V 273.301"
            />
            <path
              id="path26"
              style={{ fill: '#ffffff' }}
              d="m 1052.53,178.184 v -31.707 h 21.92 v -46.196 c -8.84,-1.2654 -12.82,-1.5115 -19.77,-1.5115 -13.99,0 -20.71,3.6135 -24.06,13.0465 -2.72,7.286 -3.36,18.848 -3.36,59.036 0,10.054 0.32,18.25 0.64,25.515 l 0.62,16.438 c 0.89,25.261 6.98,33.75 24.32,33.75 14.9,0 21.27,-7.297 21.27,-24.653 l 0.34,-10.293 h 41.92 c -0.25,20.071 -0.57,21.567 -2.7,30.996 -4.9,22.215 -26.12,33.813 -62.65,33.813 -10.33,0 -20.1,-1.227 -28.33,-3.344 -18.22,-5.5 -32.217,-22.246 -35.264,-42.972 -1.817,-13.934 -3.367,-41.918 -3.367,-66.235 0,-29.211 0.625,-43.203 3.367,-56.015 4.855,-26.4965 27.094,-40.1762 64.184,-40.1762 21.87,0 41.99,3.0469 64.76,8.8476 V 178.184 h -63.84"
            />
            <path
              id="path28"
              style={{ fill: '#ffffff' }}
              d="m 1181.28,169.922 c 0.32,17.644 2.17,21.25 11.92,21.25 10.01,0 12.44,-4.199 12.44,-21.25 v -11.524 c -6.43,0.254 -9.45,0.254 -12.77,0.254 -39.27,0 -52.97,-12.5 -52.97,-48.984 0,-27.9805 10.39,-40.7227 32,-40.7227 13.68,0 23.08,4.5781 33.74,17.3047 l 0.61,-15.5195 h 38.93 c -1.17,11.25 -1.53,15.8398 -1.53,21.332 v 9.7145 56.621 c 0,25.84 -1.5,33.118 -8.52,41.309 -6.68,8.258 -21.61,12.793 -40.72,12.793 -15.22,0 -27.99,-2.766 -37.45,-8.203 -10.62,-6.094 -13.4,-13.125 -13.69,-34.375 z m 24.36,-32.559 v -17.324 c 0,-20.0898 -4.28,-27.7148 -15.21,-27.7148 -9.15,0 -12.47,5.4961 -12.47,20.3708 0,19.825 4.22,24.668 21.25,24.668 h 6.43"
            />
            <path
              id="path30"
              style={{ fill: '#ffffff' }}
              d="m 1365.06,169.297 c 0,16.125 -1.8,22.558 -8.49,30.078 -7.91,8.84 -20.65,13.125 -39.54,13.125 -32.55,0 -51.11,-14.297 -51.11,-39.238 0,-16.114 7.86,-26.426 28.02,-36.504 l 10.29,-4.863 c 21.29,-10.665 25.57,-14.922 25.57,-25.293 0,-8.7895 -5.44,-14.8832 -13.68,-14.8832 -9.46,0 -12.8,5.2148 -13.09,18.5942 0,1.503 0,3.664 -0.28,6.375 h -37.77 v -3.7 c 0,-30.4372 16.78,-44.0427 52.96,-44.0427 31.28,0 50.48,15.7617 50.48,42.2847 0,10.293 -3.64,19.688 -10.3,26.458 -6.09,6.074 -14.34,11.218 -29.52,18.835 l -8.5,4.266 c -12.19,6.086 -16.46,10.949 -16.46,18.254 0,7.93 4.27,12.129 11.86,12.129 8.2,0 11.87,-4.512 11.87,-15.469 0,-1.523 0.33,-3.633 0.6,-6.406 h 37.09"
            />
          </g>
        </g>
      </svg>
    );

    switch (logoName) {
      case 'sempra energy':
        return sempraLogo;

      case 'sdge':
        return sdgeLogo;

      case 'socal':
        return socalLogo;

      case 'sempra external':
        return sempraExtrenalLogo;

      case 'sdge external':
        return sdgeExternal;

      case 'socal external':
        return socalExternal;

      case 'socal gas':
        return socalGasLogo;

      default:
        return sempraLogo;
    }
  };
  return <div style={{ lineHeight: 0 }}>{getLogo()}</div>;
};

export default ATMBrandLogo;
