import React, { useState } from 'react';
import {
  Label,
  LabelProps,
  LabelDetailProps,
  LabelGroupProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMLabel.module.scss';

export type colors =
  | 'lightgreen'
  | 'lightred'
  | 'lightorange'
  | 'lightteal'
  | 'lightviolet'
  | 'lightgrey'
  | 'lightblue'
  | 'lightyellow'
  | 'darkteal';

export type IATMLabelProps = LabelProps & {
  customcolor?: colors;
  transition?: boolean;
};

export interface IATMLabel extends React.FC<IATMLabelProps> {
  Detail: typeof Label.Detail;
  Group: typeof Label.Group;
}

const ATMLabel: IATMLabel = ({ children, text, ...props }) => {
  const [transit, setTransit] = useState(false);

  const getInitials = (value: string) => {
    if (value) {
      const matches = value.match(/\b(\w)/g);
      return matches?.join('');
    }

    return null;
  };

  const getText = (value: any) => {
    return transit ? value : getInitials(value);
  };

  if (props.transition) {
    return (
      <Label
        className={classNames(props.className, styles.transitionLabel, {
          [styles.lightgreen]: props.customcolor === 'lightgreen',
          [styles.lightred]: props.customcolor === 'lightred',
          [styles.lightorange]: props.customcolor === 'lightorange',
          [styles.lightteal]: props.customcolor === 'lightteal',
          [styles.lightviolet]: props.customcolor === 'lightviolet',
          [styles.lightgrey]: props.customcolor === 'lightgrey',
          [styles.lightblue]: props.customcolor === 'lightblue',
          [styles.lightyellow]: props.customcolor === 'lightyellow',
          [styles.darkteal]: props.customcolor === 'darkteal',
        })}
        {...props}
        onMouseEnter={() => setTransit(true)}
        onMouseLeave={() =>
          setTimeout(() => {
            setTransit(false);
          }, 1000)
        }
      >
        {getText(props.content)}
      </Label>
    );
  }

  return (
    <Label
      {...props}
      className={classNames(props.className, {
        [styles.lightgreen]: props.customcolor === 'lightgreen',
        [styles.lightred]: props.customcolor === 'lightred',
        [styles.lightorange]: props.customcolor === 'lightorange',
        [styles.lightteal]: props.customcolor === 'lightteal',
        [styles.lightviolet]: props.customcolor === 'lightviolet',
        [styles.lightgrey]: props.customcolor === 'lightgrey',
        [styles.lightblue]: props.customcolor === 'lightblue',
        [styles.lightyellow]: props.customcolor === 'lightyellow',
        [styles.darkteal]: props.customcolor === 'darkteal',
      })}
    >
      {children}
    </Label>
  );
};

ATMLabel.Detail = Label.Detail;
ATMLabel.Group = Label.Group;

export type IATMLabelDetailProps = LabelDetailProps;
export type IATMLabelGroupProps = LabelGroupProps;

export { ATMLabel };
