import React from 'react';
import {
  Message,
  MessageProps,
  MessageContentProps,
  MessageHeaderProps,
  MessageItemProps,
  MessageListProps,
} from 'semantic-ui-react';

export type IATMMessageProps = MessageProps;

interface IATMMessage extends React.FC<IATMMessageProps> {
  Content: typeof Message.Content;
  Header: typeof Message.Header;
  Item: typeof Message.Item;
  List: typeof Message.List;
}

const ATMMessage: IATMMessage = ({ children, ...props }) => {
  return <Message {...props}>{children}</Message>;
};

ATMMessage.Content = Message.Content;
ATMMessage.Header = Message.Header;
ATMMessage.Item = Message.Item;
ATMMessage.List = Message.List;

export type IATMMessageContentProps = MessageContentProps;
export type IATMMessageHeaderProps = MessageHeaderProps;
export type IATMMessageItemProps = MessageItemProps;
export type IATMMessageListProps = MessageListProps;

export { ATMMessage };
