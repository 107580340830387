// Call the component at the top most level of the application.

import React, { useState } from 'react';
import {
  Message,
  MessageProps,
  MessageContentProps,
  MessageHeaderProps,
  MessageItemProps,
  MessageListProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMSystemMessage.module.scss';

import { ATMIcon } from '../ATMIcon/ATMIcon.component';

export type IATMSystemProps = MessageProps & {
  notificationType: string;
};

export interface IATMSystemMessage extends React.FC<IATMSystemProps> {
  Content: typeof Message.Content;
  Header: typeof Message.Header;
  Item: typeof Message.Item;
  List: typeof Message.List;
}

const ATMSystemMessage: IATMSystemMessage = (props) => {
  const [viewMsg, setMsg] = useState(true);

  return (
    <div>
      {viewMsg && (
        <Message
          className={classNames(props.className, styles.systemMessage, {
            [styles.systemMessageWarning]: props.notificationType === 'warning',
            [styles.systemMessageError]: props.notificationType === 'error',
            [styles.systemMessageInfo]: props.notificationType === 'info',
          })}
          size="tiny"
          onDismiss={() => setMsg(false)}
          {...props}
        >
          <div>
            <ATMIcon
              name={
                props.notificationType === 'warning' ||
                props.notificationType === 'error'
                  ? 'warning circle'
                  : 'info circle'
              }
              className={styles.notificationIcon}
            />
            <p className={styles.systemNotification}>
              <b>{props.header}</b> - {props.content}
            </p>
          </div>
        </Message>
      )}
    </div>
  );
};

ATMSystemMessage.Content = Message.Content;
ATMSystemMessage.Header = Message.Header;
ATMSystemMessage.Item = Message.Item;
ATMSystemMessage.List = Message.List;

export type IATMSystemMessageContentProps = MessageContentProps;
export type IATMSystemMessageHeaderProps = MessageHeaderProps;
export type IATMSystemMessageItemProps = MessageItemProps;
export type IATMSystemessageListProps = MessageListProps;

export { ATMSystemMessage };
