import React from 'react';
import dompurify from 'dompurify';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import styles from './ATMFooter.module.scss';

export type ATMFooterProps = {
  appName?: string;
  footer?: string;
  customized?: boolean;
  content?: string;
  customText?: any;
};

const ATMFooter: React.FC<ATMFooterProps> = ({
  appName = 'Sempra Energy',
  footer,
  customized = false,
  customText,
  content = '',
}) => {
  const date = new Date();
  const currentYear = date.getFullYear();
  const copyrightCustom = `${currentYear} ${appName} All rights reserved.`;
  const copyrightStandard = `${currentYear} ${appName}. All Copyright and Trademark rights reserved.`;
  const copyrightOwner = `${currentYear} ${appName}. Trademarks are property of their respective owners. All rights reserved.`;
  const copyrightEqual = `${currentYear} Trademarks are property of their respective owners. All rights reserved.`;

  const getCopyRightText = (value: string) => {
    switch (value) {
      case 'footer1':
        return copyrightOwner;
      case 'footer2':
        return copyrightEqual;
      default:
        return copyrightStandard;
    }
  };
  const sanitizer = dompurify.sanitize;

  if (customized) {
    return (
      <div>
        {customText && (
          <div
            className={styles.customText}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: sanitizer(customText) }}
          />
        )}
        <div className={styles.customFooter}>
          <ATMIcon name="copyright outline" />
          <p>{`${copyrightCustom} ${content}`}</p>
        </div>
      </div>
    );
  }
  return (
    <div>
      {customText && (
        <div
          className={styles.customText}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizer(customText) }}
        />
      )}
      {footer && (
        <div className={styles.customFooter}>
          <ATMIcon name="copyright outline" />
          <p>{getCopyRightText(footer)}</p>
        </div>
      )}
    </div>
  );
};

export { ATMFooter };
