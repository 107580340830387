import React from 'react';
import {
  Popup,
  PopupProps,
  PopupHeaderProps,
  PopupContentProps,
} from 'semantic-ui-react';

export interface IATMPopover extends React.FC<PopupProps> {
  Header: typeof Popup.Header;
  Content: typeof Popup.Content;
}

const ATMPopover: IATMPopover = ({ children, ...props }) => (
  <Popup {...props}>{children}</Popup>
);

ATMPopover.Header = Popup.Header;
ATMPopover.Content = Popup.Content;

export type IATMPopoverProps = PopupProps;
export type IATMPopupHeaderProps = PopupHeaderProps;
export type IATMPopupContentProps = PopupContentProps;

export { ATMPopover };
