import React, { useCallback, useEffect, useState } from 'react';
import { TimeInput, TimeInputProps } from 'semantic-ui-calendar-react';
import { Input, InputProps } from 'semantic-ui-react';
import classNames from 'classnames';
import { restrictAngularBracketSymbols } from '../../../utils/security';
import { ATMLabel } from '../ATMLabel/ATMLabel.component';
import styles from './ATMTimePicker.module.scss';

export type IATMTimePickerProps = TimeInputProps &
  InputProps & {
    is24hourformat?: boolean;
    errorMessage?: string;
    timeFormatErrorMessage?: string;
    isSystemFormat?: boolean;
    restrictAngularBrackets?: boolean;
  };

export const REGEX_COLON = /([a-f0-9]{2})([a-f0-9]{1})/i;
export const REGEX_VALIDATION = /^\d{1,2}:\d{2}?$/;
export const REGEX_VALIDATION_WITH_AMPM = /^\d{1,2}:\d{2} ([AP]M)?$/;

export const formatTime = (value = ''): string => {
  let formattedTimeValue = value.replace(/[^a-f0-9] ([AP]M)/gi, '');

  while (REGEX_COLON.test(formattedTimeValue)) {
    formattedTimeValue = formattedTimeValue.replace(REGEX_COLON, '$1:$2');
  }
  return formattedTimeValue;
};

export const handleValidation = (
  value: string | any,
  props: React.PropsWithChildren<IATMTimePickerProps>
): string => {
  let regArray: string[] = [];
  let errorMessage = '';
  if (value !== '') {
    // eslint-disable-next-line no-cond-assign
    if ((regArray = value.match(REGEX_VALIDATION)) && props.is24hourformat) {
      regArray = value.split(':');

      if (Number(regArray[0]) > 23) {
        errorMessage =
          props.errorMessage === undefined
            ? 'Invalid time'
            : props.errorMessage;
      }

      if (!errorMessage && Number(regArray[1]) > 59) {
        errorMessage =
          props.errorMessage === undefined
            ? 'Invalid time'
            : props.errorMessage;
      }
    } else if (!props.is24hourformat) {
      let regMinuteArray: string[] = [];
      regArray = value.split(':');
      if (regArray[1]) {
        regMinuteArray = regArray[1].split(' ');
      }
      if (Number(regArray[0]) < 1 || Number(regArray[0]) > 12) {
        errorMessage =
          props.errorMessage === undefined
            ? 'Invalid time'
            : props.errorMessage;
      }

      if (!errorMessage && Number(regMinuteArray[0]) > 59) {
        errorMessage =
          props.errorMessage === undefined
            ? 'Invalid time'
            : props.errorMessage;
      }

      if (
        !errorMessage &&
        (regArray[1] === undefined ||
          regArray[1] === '' ||
          regMinuteArray[0] === '')
      ) {
        errorMessage =
          props.timeFormatErrorMessage === undefined
            ? 'Please enter time in correct format (HH:MM AM/PM)'
            : props.timeFormatErrorMessage;
      }

      if (!errorMessage && regMinuteArray[1] === '') {
        errorMessage =
          props.timeFormatErrorMessage === undefined
            ? 'Please enter time in correct format (HH:MM AM/PM)'
            : props.timeFormatErrorMessage;
      }

      if (
        !errorMessage &&
        regMinuteArray[1] !== 'AM' &&
        regMinuteArray[1] !== 'PM'
      ) {
        errorMessage =
          props.timeFormatErrorMessage === undefined
            ? 'Please enter time in correct format (HH:MM AM/PM)'
            : props.timeFormatErrorMessage;
      }
    } else {
      errorMessage =
        props.timeFormatErrorMessage === undefined
          ? 'Please enter time in correct format (HH:MM)'
          : props.timeFormatErrorMessage;
    }
  }
  return errorMessage;
};

export const ATMTimePicker: React.FC<IATMTimePickerProps> = (props) => {
  const { onChange, value: originalValue } = props;
  const [time, setTime] = useState<string>(originalValue);
  const [value, setValue] = useState<string>(originalValue);
  const [errorMessage, setErrorMessage] = useState<string>('');

  useEffect(() => {
    let error = '';
    error = handleValidation(props.value, props);
    if (error !== '') {
      setErrorMessage(error);
    } else {
      setErrorMessage('');
    }
  }, []);

  const handleChange = useCallback(
    (event, data) => {
      let result = props.restrictAngularBrackets
        ? restrictAngularBracketSymbols(data.value)
        : data.value;
      let error = '';
      setTime(() => {
        result = formatTime(result);
        if (onChange) {
          onChange(event, {
            ...data,
            time: result,
          });
        }

        return result;
      });

      error = handleValidation(result, props);
      if (error !== '') {
        setErrorMessage(error);
      } else {
        setErrorMessage('');
      }

      return result;
    },
    [onChange, setTime]
  );

  const handleTimepicker = useCallback(
    (event, data) => {
      const result = props.restrictAngularBrackets
        ? restrictAngularBracketSymbols(event.currentTarget.value)
        : event.currentTarget.value;

      setValue(() => {
        // This will handle onChange from react hook form
        if (onChange) {
          onChange(event, {
            ...data,
            value: result,
          });
        }

        return result;
      });

      return result;
    },
    [onChange, setValue]
  );

  // eslint-disable-next-line react/destructuring-assignment
  if (props.isSystemFormat) {
    return (
      <Input
        size="small"
        type="time"
        className={styles.timepicker}
        {...{
          ...props,
          onChange: handleTimepicker,
          onPaste: handleTimepicker,
          value,
        }}
      />
    );
  }

  return (
    <div className={errorMessage === '' ? ' ' : styles.errorField}>
      <div className={styles.time}>
        <TimeInput
          className={classNames(props.className, styles.small)}
          {...{
            ...props,
            onChange: handleChange,
            name: 'time',
            placeholder: 'Time',
            iconPosition: 'right',
            animation: 'glow',
            value: time,
            clearable: true,
            autoComplete: 'off',
            closable: true,
          }}
          timeFormat={
            // eslint-disable-next-line react/destructuring-assignment
            props.is24hourformat === undefined || props.is24hourformat
              ? '24'
              : 'AMPM'
          }
        />
      </div>

      {errorMessage && (
        <div>
          <ATMLabel basic className={styles.errorLabel} pointing="above">
            {errorMessage}
          </ATMLabel>
        </div>
      )}
    </div>
  );
};
