import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isNull, isArray, isString, isUndefined } from 'util';
import {
  IORGDataTableQueryState,
  IORGDataTableOnUpdate,
} from '../ORGDataTable.component';
import styles from './MOLTable-filter.module.scss';
import { ATMGrid } from '../../../atoms/ATMGrid/ATMGrid.component';
import { ATMResponsive } from '../../../atoms/ATMResponsive/ATMResponsive.component';
import { ATMModal } from '../../../atoms/ATMModal/ATMModal.component';
import { ATMButton } from '../../../atoms/ATMButton/ATMButton.component';

type IMOLTableFilterFunction = (props: {
  values: Record<string, any>;
  errors: Record<string, any>;
  setValue: (name: string, value: any) => void;
  setError: (name: string, error: any) => void;
}) => React.ReactNode;

export type IMOLTableFilterItemProps = (props: {
  value: any;
  error: any;
  setValue: (value: any) => void;
  setError: (error: any) => void;
}) => React.ReactNode;

export type IMOLTableFilter =
  | IMOLTableFilterFunction
  | Record<string, IMOLTableFilterItemProps>;

export type IMOLTableFilterProps = {
  filterCollapsed?: boolean;
  state: IORGDataTableQueryState;
  filters: IMOLTableFilter;
  handleToggle: () => void;
  handleChange: IORGDataTableOnUpdate;
  errors: Record<string, any>;
  handleError: (name: string, error: any) => void;
};

type IFilterItemProps = {
  name: string;
  filter: IMOLTableFilterItemProps;
  value: any;
  handleChange: (data: { name: string; value: any }) => void;
  errors: Record<string, any>;
  handleError: (name: string, error: any) => void;
};

export const FilterItem: React.FC<IFilterItemProps> = ({
  name,
  filter,
  value,
  handleChange,
  errors,
  handleError,
}) => {
  // The purpose for this is to make update of value in realtime
  const [cacheValue, setCacheValue] = useState(value);

  useEffect(() => {
    if (cacheValue !== value) {
      setCacheValue(value);
    }
  }, [cacheValue, value, setCacheValue]);

  return (
    <>
      {filter({
        value: cacheValue,
        // eslint-disable-next-line security/detect-object-injection
        error: errors[name],
        setValue: (val: any) => {
          handleChange({
            name,
            value: val,
          });

          setImmediate(() => setCacheValue(val));
        },
        setError: (e: any) => handleError(name, e),
      })}
    </>
  );
};

export type ITableFilter = {
  name: string;
  value: any;
};

const MOLTableFilter: React.FC<IMOLTableFilterProps> = ({
  state,
  filterCollapsed,
  filters,
  handleToggle,
  handleChange,
  errors,
  handleError,
}) => {
  // We will generate filter object based on state filters initial values
  const [filterValues, setFilterValues] = useState<Record<string, any>>(
    (state.filters ?? []).reduce(
      (list: Record<string, any>, item: ITableFilter) => {
        return {
          ...list,
          [item.name]: item.value,
        };
      },
      {}
    )
  );

  const handleFilterChange = useCallback(
    (field: string, value: any) => {
      setFilterValues((values) => ({
        ...values,
        [field]: value,
      }));
    },
    [setFilterValues]
  );

  const handleClear = useCallback(() => {
    const filterList =
      typeof filters === 'function'
        ? (state.filters || []).map((v) => v.name)
        : Object.keys(filters || {});

    if (!filterCollapsed) {
      handleToggle();
    } else {
      handleToggle();
      setImmediate(handleToggle);
    }

    filterList.forEach((v) => handleError(v, undefined));
    setFilterValues({});
    handleChange({
      ...state,
      filters: (state.filters || []).filter(
        (value) => !filterList.includes(value.name)
      ),
      page: 1, // Redirect to page 1
    });
  }, [
    handleError,
    state,
    filters,
    setFilterValues,
    handleChange,
    handleToggle,
    filterCollapsed,
  ]);

  const handleApply = useCallback(() => {
    if (!filterCollapsed) {
      handleToggle();
    }
    handleChange({
      ...state,
      filters: Object.entries(filterValues)
        .map(([key, value]) => ({
          name: key,
          value,
        }))
        .filter((item) => {
          // Filter out empty values
          if (
            (isString(item.value) && !item.value.trim().length) ||
            (isArray(item.value) && !item.value.length) ||
            isNull(item.value) ||
            isUndefined(item.value)
          ) {
            return false;
          }

          return true;
        }),
      page: 1, // Redirect to page 1
    });
  }, [filterValues, state, handleToggle, handleChange, filterCollapsed]);

  const handleChangeItem = useCallback(
    (value) => {
      setFilterValues((values) => ({
        ...values,
        [value.name]: value.value,
      }));
    },
    [state, setFilterValues]
  );

  const generateFilterColumns = useMemo(() => {
    const filterList = Object.entries(filters).map(
      ([key, filter]: [string, IMOLTableFilterItemProps]) => ({
        name: key,
        filter,
      })
    );

    return (
      <div className="ui form">
        <ATMGrid doubling stackable columns={5}>
          {filterList.map((item) => (
            <ATMGrid.Column key={item.name}>
              <FilterItem
                {...item}
                value={filterValues[item.name]}
                handleChange={handleChangeItem}
                errors={errors}
                handleError={handleError}
              />
            </ATMGrid.Column>
          ))}
        </ATMGrid>
      </div>
    );
  }, [filters, filterValues, errors, handleError]);

  const content = (
    <div className={styles.container}>
      {typeof filters === 'function'
        ? filters({
            values: filterValues,
            errors,
            setValue: handleFilterChange,
            setError: handleError,
          })
        : generateFilterColumns}
    </div>
  );

  return (
    <>
      <ATMResponsive media="mobile">
        <ATMModal size="fullscreen" open>
          <ATMModal.Header className={styles.modalHeader}>
            <div>
              <ATMButton secondary onClick={handleToggle}>
                Close
              </ATMButton>
              <span>Filters</span>
              <div>
                <ATMButton secondary onClick={handleClear}>
                  Clear
                </ATMButton>
                <ATMButton
                  primary
                  onClick={handleApply}
                  disabled={Object.values(errors).length > 0}
                >
                  Apply
                </ATMButton>
              </div>
            </div>
          </ATMModal.Header>
          <ATMModal.Content className={styles.modalContent}>
            {content}
          </ATMModal.Content>
        </ATMModal>
      </ATMResponsive>

      <ATMResponsive greaterThan="mobile">
        <div className={styles.wrapper}>
          {content}

          <div className={styles.actions}>
            <ATMButton secondary onClick={handleClear}>
              Clear
            </ATMButton>
            <ATMButton
              primary
              onClick={handleApply}
              disabled={
                Object.keys(errors).filter((v) =>
                  filters ? Object.keys(filters).includes(v) : true
                ).length > 0
              }
            >
              Apply
            </ATMButton>
          </div>
        </div>
      </ATMResponsive>
    </>
  );
};

export default MOLTableFilter;
