import React, { useState, useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { Visibility, Sidebar, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';
import {
  MOLNavigation,
  IMOLNavigationMenuProps,
} from '../../molecules/MOLNavigation/MOLNavigation.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';
import styles from './ORGHeader.module.scss';
import {
  ATMUserMenu,
  IATMUserMenuProps,
} from '../../atoms/ATMUserMenu/ATMUserMenu.component';
import { MOLAppDropdown } from '../../molecules/MOLAppDropdown/MOLAppDropdown.component';
import { ATMLabel } from '../../atoms/ATMLabel/ATMLabel.component';
import {
  ATMSearch,
  IATMSearchProps,
} from '../../atoms/ATMSearch/ATMSearch.component';
import { ATMBrandLogo } from '../../atoms/ATMBrandLogo/ATMBrandLogo.component';
import { IATMMenuItemProps } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

export type IORGHeaderProps = {
  homeLink?: string;
  environment?: string | boolean;
  utility?: React.ReactNode;
  application?: boolean | string;
  menus: IMOLNavigationMenuProps[];
  search?: IATMSearchProps;
  showUser?: boolean;
  user?: IATMUserMenuProps['user'];
  onNavigationClick?: (data: IATMMenuItemProps) => void;
  onLogout?: () => void;
  siteLink?: boolean;
  sitePath?: string;
  siteName?: string;
  showHelp?: boolean;
  logoName?: string;
  helpLinkUrl?: string;
  settingLinkUrl?: string;
  showHelpInUserMenuDd?: boolean;
  showSettingInUserMenuDd?: boolean;
  additionalMenu?: React.ReactNode;
  menuCount?: number;
  globalSearch?: React.ReactNode;
  notificationContent?: string;
  isBellIcon?: boolean;
  closeSearch?: boolean;
  headerActions?: React.ReactNode;
  isTitleMenu?: boolean;
  logoWidth?: number;
};

const DesktopContainer: React.FC<IORGHeaderProps> = ({
  homeLink = '/',
  environment = false,
  menus,
  application = false,
  utility,
  search,
  children,
  showUser = true,
  user,
  onNavigationClick,
  onLogout,
  siteLink = false,
  sitePath = '#',
  siteName = 'View App site',
  showHelp = false,
  logoName = 'Sempra Energy',
  helpLinkUrl = '/userguide',
  settingLinkUrl = 'https://myaccount.microsoft.com/',
  menuCount,
  additionalMenu,
  globalSearch,
  isBellIcon = false,
  headerActions,
  isTitleMenu = false,
  logoWidth = 100,
  showHelpInUserMenuDd = true,
  showSettingInUserMenuDd = true,
}) => {
  const [fixed, setFixed] = useState(false);

  return (
    <div id="parentContainer">
      <Visibility
        once={false}
        onBottomPassed={() => setFixed(true)}
        onBottomPassedReverse={() => setFixed(false)}
      >
        <div className={styles.wrapper}>
          <div className={styles.sempraNewHeader}>
            <div>
              <ul className={styles.branding}>
                <li
                  className={
                    logoName.toString().toLowerCase() === 'socal'
                      ? styles.logoSocal
                      : styles.newHeaderlogoOther
                  }
                >
                  <Link to={homeLink} className={styles.logo}>
                    <ATMBrandLogo
                      // height={30}
                      width={logoWidth}
                      logoName={logoName}
                      color="#ffffff"
                    />
                    <h1>Sempra Energy</h1>
                  </Link>
                </li>
                {utility && <li>{utility}</li>}

                <li>
                  {application && (
                    <div className={styles.newHeaderApplicationContainer}>
                      <MOLAppDropdown application={application} />
                    </div>
                  )}
                </li>

                {environment !== false &&
                  !environment.toString().toLowerCase().includes('prod') && (
                    <li
                      className={
                        logoName.toString().toLowerCase() === 'socal'
                          ? styles.logoSocal
                          : styles.newHeaderlogoOther
                      }
                    >
                      {logoName === 'socal gas' ? (
                        <ATMLabel
                          className={
                            environment.toString().toLowerCase().includes('dev')
                              ? styles.environmentDev
                              : styles.environmentSocal
                          }
                          horizontal
                        >
                          {environment}
                        </ATMLabel>
                      ) : (
                        <ATMLabel
                          className={
                            environment.toString().toLowerCase().includes('dev')
                              ? styles.environmentDev
                              : styles.environmentTestProd
                          }
                          horizontal
                        >
                          {`${environment || 'Dev'}`}
                        </ATMLabel>
                      )}
                    </li>
                  )}
              </ul>
            </div>
            {search && (
              <div>
                <ATMSearch
                  size="mini"
                  {...search}
                  className={classNames(styles.searchBar, search.className)}
                />
              </div>
            )}
            {globalSearch && (
              <div className={styles.globalSearchBar}> {globalSearch}</div>
            )}
            <div className={styles.displayFlex}>
              {showHelp && (
                <div className={styles.helpLink}>
                  <ATMIcon
                    className={styles.helpIcon}
                    onClick={() => {
                      window.open(`${helpLinkUrl}`, '_blank');
                    }}
                    circular
                    name="help"
                  />
                </div>
              )}

              {isBellIcon && (
                <ATMPopover
                  trigger={
                    <ATMIcon
                      name="bell outline"
                      size="big"
                      style={{ marginRight: '41px' }}
                    />
                  }
                  content={
                    <>
                      <Grid className={styles.notificationHead}>
                        <Grid.Row>
                          <Grid.Column width={7}>
                            <strong>Notifications (17)</strong>
                          </Grid.Column>
                          <Grid.Column width={5} style={{ color: '#009BDA' }}>
                            Mark all as read
                          </Grid.Column>
                          <Grid.Column width={4} style={{ color: '#009BDA' }}>
                            Dismiss all
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <div className={styles.notificationCont}>
                        <Grid
                          className={styles.notificationItem}
                          style={{ background: '#E8F3F8' }}
                        >
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <ATMIcon
                                name="check circle outline"
                                size="big"
                                style={{ color: '#619602' }}
                              />
                              <div className="duration">45 mins</div>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <span className="nt-card__text--3-line">
                                <span className="white-space-pre"> </span>
                                <span className="white-space-pre"> </span>The
                                Regular Bill, dated 05/08/2020 08:07:44, for{' '}
                                <strong>
                                  Chevron Energy Solutions Company
                                </strong>
                                , download was successful.
                              </span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <ATMIcon name="remove" size="large" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid
                          className={styles.notificationItem}
                          style={{ background: '#E8F3F8' }}
                        >
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <ATMIcon
                                name="exclamation circle"
                                size="big"
                                style={{ color: '#E70031' }}
                              />
                              <div className="duration">45 mins</div>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <span className="nt-card__text--3-line">
                                <span className="white-space-pre"> </span>
                                <span className="white-space-pre"> </span>The
                                Regular Bill, dated 05/08/2020 08:07:44, for{' '}
                                <strong>
                                  Chevron Energy Solutions Company
                                </strong>
                                , download was successful.
                              </span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <ATMIcon name="remove" size="large" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid className={styles.notificationItem}>
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <ATMIcon
                                name="check circle outline"
                                size="big"
                                style={{ color: '#619602' }}
                              />
                              <div className="duration">45 mins</div>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <span className="nt-card__text--3-line">
                                <span className="white-space-pre"> </span>
                                <span className="white-space-pre"> </span>The
                                Regular Bill, dated 05/08/2020 08:07:44, for{' '}
                                <strong>
                                  Chevron Energy Solutions Company
                                </strong>
                                , download was successful.
                              </span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <ATMIcon name="remove" size="large" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid className={styles.notificationItem}>
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <ATMIcon
                                name="check circle outline"
                                size="big"
                                style={{ color: '#619602' }}
                              />
                              <div className="duration">45 mins</div>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <span className="nt-card__text--3-line">
                                <span className="white-space-pre"> </span>
                                <span className="white-space-pre"> </span>The
                                Regular Bill, dated 05/08/2020 08:07:44, for{' '}
                                <strong>
                                  Chevron Energy Solutions Company
                                </strong>
                                , download was successful.
                              </span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <ATMIcon name="remove" size="large" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid className={styles.notificationItem}>
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <ATMIcon
                                name="check circle outline"
                                size="big"
                                style={{ color: '#619602' }}
                              />
                              <div className="duration">45 mins</div>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <span className="nt-card__text--3-line">
                                <span className="white-space-pre"> </span>
                                <span className="white-space-pre"> </span>The
                                Regular Bill, dated 05/08/2020 08:07:44, for{' '}
                                <strong>
                                  Chevron Energy Solutions Company
                                </strong>
                                , download was successful.
                              </span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <ATMIcon name="remove" size="large" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                        <Grid className={styles.notificationItem}>
                          <Grid.Row>
                            <Grid.Column width={3}>
                              <ATMIcon
                                name="check circle outline"
                                size="big"
                                style={{ color: '#619602' }}
                              />
                              <div className="duration">45 mins</div>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <span className="nt-card__text--3-line">
                                <span className="white-space-pre"> </span>
                                <span className="white-space-pre"> </span>The
                                Regular Bill, dated 05/08/2020 08:07:44, for{' '}
                                <strong>
                                  Chevron Energy Solutions Company
                                </strong>
                                , download was successful.
                              </span>
                            </Grid.Column>
                            <Grid.Column width={2}>
                              <ATMIcon name="remove" size="large" />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </>
                  }
                  size="mini"
                  on="click"
                />
              )}

              {headerActions && (
                <div className={styles.notificationIcon}>{headerActions}</div>
              )}

              {showUser ? (
                <div>
                  <ATMUserMenu
                    user={user}
                    onLogout={onLogout}
                    helpLinkUrl={helpLinkUrl}
                    isTitleMenu={isTitleMenu}
                    settingLinkUrl={settingLinkUrl}
                    showHelpInUserMenuDd={showHelpInUserMenuDd}
                    showSettingInUserMenuDd={showSettingInUserMenuDd}
                  />
                </div>
              ) : (
                <ATMUserMenu
                  user={user}
                  onLogout={onLogout}
                  helpLinkUrl={helpLinkUrl}
                  socalGas
                  settingLinkUrl={settingLinkUrl}
                  showHelpInUserMenuDd={showHelpInUserMenuDd}
                  showSettingInUserMenuDd={showSettingInUserMenuDd}
                />
              )}
            </div>
          </div>
          <div
            id="navigation"
            className={
              styles.navigation + (fixed ? ` ${styles.fixedNavigation}` : '')
            }
          >
            <div>
              {application === 'socalMenu' && <ATMIcon name="th" />}
              {application !== 'socalMenu' ? (
                <MOLNavigation
                  menus={menus}
                  pointing
                  secondary
                  menuCount={menuCount}
                  onClick={onNavigationClick}
                  size="small"
                  siteLink={siteLink}
                />
              ) : (
                <MOLNavigation
                  menus={menus}
                  pointing
                  secondary
                  menuCount={menuCount}
                  onClick={onNavigationClick}
                  siteLink={siteLink}
                />
              )}
            </div>

            {siteLink && (
              <a
                className={styles.siteTag}
                href={sitePath}
                target="_blank"
                rel="noopener noreferrer"
              >
                {siteName}
                <span>
                  <ATMIcon
                    className={styles.siteTag}
                    name="external alternate"
                  />
                </span>
              </a>
            )}
            {additionalMenu && <div> {additionalMenu} </div>}
          </div>
        </div>
      </Visibility>
      {children}
    </div>
  );
};

const MobileContainer: React.FC<IORGHeaderProps> = ({
  homeLink = '/',
  menus,
  application = false,
  search,
  children,
  showUser = true,
  user,
  onNavigationClick,
  onLogout,
  logoName,
  globalSearch,
  closeSearch,
  logoWidth,
  settingLinkUrl = 'https://myaccount.microsoft.com/',
  showHelpInUserMenuDd = true,
  showSettingInUserMenuDd = true,
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false);
  const [searchOpened, setSearchOpened] = useState(false);
  const [closeSearchBar, setCloseSearchBar] = useState(closeSearch);

  const handleIcon = useCallback(() => {
    setSidebarOpened(true);
  }, [setSidebarOpened]);

  const handleSearchOpened = useCallback(
    (isSearchOpened) => {
      setSearchOpened(isSearchOpened);
      setCloseSearchBar(false);
    },
    [searchOpened, setSearchOpened]
  );

  useEffect(() => {
    if (closeSearch) {
      setCloseSearchBar(closeSearch);
    } else {
      setCloseSearchBar(!closeSearch);
    }
  }, [closeSearch]);

  return (
    <Sidebar.Pushable id="parentContainer">
      <Sidebar
        animation="push"
        inverted
        onHide={() => setSidebarOpened(false)}
        vertical
        visible={sidebarOpened}
        className={styles.newSidebar}
      >
        {application && (
          <MOLAppDropdown
            application={logoName !== 'socal gas' ? application : ''}
            isMobile
          />
        )}
        <MOLNavigation menus={menus} vertical onClick={onNavigationClick} />
        {showUser ? (
          <ATMUserMenu
            user={user}
            isMobile
            onLogout={onLogout}
            settingLinkUrl={settingLinkUrl}
            showHelpInUserMenuDd={showHelpInUserMenuDd}
            showSettingInUserMenuDd={showSettingInUserMenuDd}
          />
        ) : (
          <ATMUserMenu
            user={user}
            onLogout={onLogout}
            socalGas
            isMobile
            settingLinkUrl={settingLinkUrl}
            showHelpInUserMenuDd={showHelpInUserMenuDd}
            showSettingInUserMenuDd={showSettingInUserMenuDd}
          />
        )}
      </Sidebar>

      <Sidebar.Pusher dimmed={sidebarOpened} className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.topNewHeaderMobile}>
            <div
              className={classNames(styles.sidebarTrigger, {
                [styles.displayNone]: !(!searchOpened || closeSearchBar),
              })}
            >
              {(!searchOpened || closeSearchBar) && (
                <ATMIcon
                  name={sidebarOpened ? 'arrow left' : 'sidebar'}
                  onClick={handleIcon}
                />
              )}
            </div>
            {(!searchOpened || closeSearchBar) && (
              <Link to={homeLink} className={styles.logo}>
                <ATMBrandLogo
                  // height={25}
                  width={logoWidth}
                  color="#ffffff"
                  logoName={logoName}
                />
                <h1>Sempra Energy</h1>
              </Link>
            )}

            {search && (
              <div
                className={classNames({
                  [styles.search]: true,
                })}
              >
                {searchOpened && !closeSearchBar ? (
                  <ATMSearch
                    size="mini"
                    {...search}
                    className={classNames(styles.searchBar, search.className)}
                  />
                ) : (
                  <ATMIcon
                    name="search"
                    onClick={() => handleSearchOpened(true)}
                  />
                )}
              </div>
            )}

            {globalSearch && (
              <div
                className={classNames({
                  [styles.search]: true,
                })}
              >
                {searchOpened && !closeSearchBar ? (
                  <div className={styles.globalSearchBar}>{globalSearch}</div>
                ) : (
                  <ATMIcon
                    name="search"
                    onClick={() => handleSearchOpened(true)}
                  />
                )}
              </div>
            )}

            {(search || globalSearch) && (
              <div
                className={classNames({
                  [styles.close]: searchOpened && !closeSearchBar,
                })}
              >
                {searchOpened && !closeSearchBar ? (
                  <ATMIcon
                    name="close"
                    onClick={() => handleSearchOpened(false)}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </div>

        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const ORGHeader: React.FC<IORGHeaderProps> = ({ children, ...props }) => {
  return (
    <div>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props}>{children}</DesktopContainer>
      </ATMResponsive>

      <ATMResponsive media="mobile">
        <MobileContainer {...props}>{children}</MobileContainer>
      </ATMResponsive>
    </div>
  );
};

export { ORGHeader };
