import React from 'react';
import classNames from 'classnames';
import { List } from 'semantic-ui-react';
import styles from './ATMSkipLinks.module.scss';

type ISkip = {
  id: string;
  text: string;
};

export type IATMSkipLinks = React.FC<{
  data: ISkip[];
}>;

type IATMSkipLinksProps = IATMSkipLinks & {
  Item: typeof List.Item;
};

const ATMSkipLinks: IATMSkipLinksProps = ({ data }) => {
  const content: React.ReactNode[] = [];

  const scrollDown = (valueId = '') => {
    const valueIdElem = document.getElementById(valueId);
    const skipLinks = document.getElementById('skipLinks');

    if (valueIdElem && skipLinks) {
      valueIdElem.scrollIntoView(true);
      skipLinks.classList.add(styles.skipLinks);
    }
  };

  const handleFocus = () => {
    const skipLinks = document.getElementById('skipLinks');
    if (skipLinks) {
      skipLinks.classList.remove(styles.skipLinks);
    }
  };

  const handleBlur = () => {
    const skipLinks = document.getElementById('skipLinks');
    if (skipLinks) {
      skipLinks.classList.add(styles.skipLinks);
    }
  };

  if (data.length) {
    data.forEach((value, key) => {
      content.push(
        <List.Item key={key}>
          <a
            href={`#${value.id}`}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={(e) => {
              e.preventDefault();
              scrollDown(value.id);
            }}
          >
            {value.text}
          </a>
        </List.Item>
      );
    });
  }

  return (
    <List
      id="skipLinks"
      className={classNames({
        [styles.skipLinks]: true,
        [styles.divider]: true,
      })}
      horizontal
    >
      {content}
    </List>
  );
};

ATMSkipLinks.Item = List.Item;

export { ATMSkipLinks };
