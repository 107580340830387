import React from 'react';
import {
  Header,
  HeaderProps,
  HeaderContentProps,
  HeaderSubheaderProps,
} from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMHeader.module.scss';

export type ATMHeaderProps = HeaderProps & {
  noMargin?: boolean;
};
export interface IATMHeader extends React.FC<ATMHeaderProps> {
  Content: typeof Header.Content;
  Subheader: typeof Header.Subheader;
}

const ATMHeader: IATMHeader = ({ children, ...props }) => (
  <Header
    className={classNames(props.className, {
      [styles.noMargin]: props.noMargin,
    })}
    {...props}
  >
    {children}
  </Header>
);

ATMHeader.Content = Header.Content;
ATMHeader.Subheader = Header.Subheader;

export type IATMHeaderProps = HeaderProps;
export type IATMHeaderContentProps = HeaderContentProps;
export type IATMHeaderSubheaderProps = HeaderSubheaderProps;

export { ATMHeader };
