/* eslint-disable */
export function getBrowser() {
  let browser_name = '';
  // Note: documentMode only works in IE https://www.w3schools.com/jsref/prop_doc_documentmode.asp
  const isIE = /*@cc_on!@*/ false || !!(document as any)['documentMode'];
  const isEdge = !isIE && !!window.StyleMedia;
  if (navigator.userAgent.indexOf('Chrome') !== -1 && !isEdge) {
    browser_name = 'chrome';
  } else if (navigator.userAgent.indexOf('Safari') !== -1 && !isEdge) {
    browser_name = 'safari';
  } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
    browser_name = 'firefox';
  } else if (
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    !!(document as any)['documentMode'] === true
  ) {
    //IF IE > 10
    browser_name = 'ie';
  } else if (isEdge) {
    browser_name = 'edge';
  } else {
    browser_name = 'other-browser';
  }
  return browser_name;
}
