import React, { useMemo, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { ATMImage } from '../ATMImage/ATMImage.component';
import {
  ATMDropdown,
  IATMDropdownItemProps,
} from '../ATMDropdown/ATMDropdown.component';
import { ATMMenu } from '../ATMMenu/ATMMenu.component';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';
import styles from './ATMUserMenu.module.scss';
import { ATMPopover } from '../ATMPopover/ATMPopover.component';

type IATMUser = {
  first_name?: string;
  last_name?: string;
  name?: string;
  username?: string;
  avatar?: string;
  fullName?: string;
};

export type IATMUserMenuProps = {
  user?: IATMUser;
  isMobile?: boolean;
  onLogout?: () => void;
  helpLinkUrl?: string;
  settingLinkUrl?: string;
  showHelpInUserMenuDd?: boolean;
  showSettingInUserMenuDd?: boolean;
  socalGas?: boolean;
  isTitleMenu?: boolean;
};

const ATMUserMenu: React.FC<IATMUserMenuProps> = ({
  user,
  isMobile = false,
  onLogout,
  helpLinkUrl = '/userguide',
  socalGas = false,
  isTitleMenu = false,
  settingLinkUrl = 'https://myaccount.microsoft.com/',
  showHelpInUserMenuDd = true,
  showSettingInUserMenuDd = true,
}) => {
  const [popOpen, setPopOpen] = useState<boolean>(false);
  const trigger = useMemo(() => {
    let content = 'Welcome';

    if (user) {
      if (user.username) {
        content = user.username;
      } else if (user.name) {
        content = user.name;
      } else if (user.first_name && user.last_name) {
        content = `${user.first_name} ${user.last_name}`;
      } else if (user.fullName) {
        content = user.fullName;
      }
    }
    return (
      <div className={styles.header}>
        <span
          className={user && Object.keys(user).length ? styles.fadeIn : ' '}
        >
          {content}
        </span>
        {user?.avatar ? (
          <ATMImage src={user?.avatar} avatar />
        ) : (
          <div className={styles.avatar}>
            <ATMIcon name="user" size="small" />
          </div>
        )}
      </div>
    );
  }, [user]);

  const optionsArray = [
    {
      key: 'sign-out',
      text: 'Logout',
      icon: 'sign out',
      link: '',
      onClick: () => {
        if (onLogout) {
          onLogout();
        }
      },
    },
  ];

  if (showHelpInUserMenuDd) {
    optionsArray.splice(0, 0, {
      key: 'help',
      text: 'Help',
      icon: 'help',
      link: helpLinkUrl,
      onClick: () => {
        window.open(`${helpLinkUrl}`, '_blank');
      },
    });
  }
  if (showSettingInUserMenuDd) {
    optionsArray.splice(0, 0, {
      key: 'account',
      text: 'Account Settings',
      icon: 'cog',
      link: settingLinkUrl,
      onClick: () => {
        window.open(`${settingLinkUrl}`, '_blank');
      },
    });
  }

  const options: IATMDropdownItemProps[] = socalGas
    ? [
        {
          key: 'account',
          text: 'My Account Info',
          icon: 'user circle outline',
          onClick: () => {
            setPopOpen(true);
          },
        },
        {
          key: 'sign-out',
          text: 'Logout',
          icon: 'sign out',
          onClick: () => {
            if (onLogout) {
              onLogout();
            }
          },
        },
      ]
    : optionsArray;

  const tilteTrigger = useMemo(() => {
    let initials = '';
    if (user?.fullName) {
      const fullName = user?.fullName?.split(' ');
      if (fullName?.length === 1) {
        initials = fullName.shift()?.charAt(0).toUpperCase() || '';
      } else {
        const firstChar = fullName.shift()?.charAt(0).toUpperCase();
        const lastChar = fullName.pop()?.charAt(0).toUpperCase();
        let fullChar = '';
        if (firstChar && lastChar) {
          fullChar = firstChar + lastChar;
        }
        initials = fullChar;
      }
    }

    return (
      <div className={styles.header}>
        <span
          style={{
            backgroundColor: '#010f3d',
            border: '1px solid #fff;',
            fontWeight: 600,
          }}
          className={styles.initials}
        >
          {initials}
        </span>
      </div>
    );
  }, [user]);

  if (isMobile) {
    return (
      <ATMMenu vertical className={styles.mobileView}>
        <ATMMenu.Item key="trigger">{trigger}</ATMMenu.Item>
        {options.map((value) => (
          <ATMMenu.Item key={value.key} onClick={() => value.onClick}>
            <ATMIcon name={value.icon as any} size="large" />
            {value?.text}
          </ATMMenu.Item>
        ))}
      </ATMMenu>
    );
  }

  const menuContent = () => {
    return (
      <>
        <div
          style={{ borderBottom: '1px solid #cfcfcd', paddingBottom: '0.5rem' }}
        >
          <strong>{user && user.fullName}</strong>
        </div>
        <div className={styles.menuOptions}>
          {options.map((optionObj, index) => (
            <li key={index}>
              <div
                role="link"
                className={styles.menuList}
                onClick={() => window.open(optionObj.link, '_blank')}
              >
                <ATMIcon name={optionObj.icon as any} />
                <p>{optionObj.text}</p>
              </div>
            </li>
          ))}
        </div>
      </>
    );
  };
  if (isTitleMenu) {
    return (
      <>
        <div className={styles.header} style={{ display: 'flex' }}>
          <ATMPopover
            trigger={tilteTrigger}
            on="click"
            content={menuContent}
            pointing="top right"
            className={styles.menuDataContent}
          />
        </div>
      </>
    );
  }

  return (
    <>
      {!popOpen ? (
        <ATMDropdown
          trigger={trigger}
          options={options}
          pointing="top right"
          icon={null}
        />
      ) : (
        <ATMPopover
          trigger={trigger}
          on="click"
          content={
            <>
              <Grid verticalAlign="middle" onClick={() => setPopOpen(false)}>
                <Grid.Column width={1}>
                  <ATMIcon name="angle left" size="large" color="blue" />
                </Grid.Column>
                <Grid.Column width={1}>
                  <ATMIcon name="user circle outline" />
                </Grid.Column>
                <Grid.Column width={12}>My Account Info</Grid.Column>
              </Grid>
              <Grid
                verticalAlign="middle"
                className={styles.mt0}
                onClick={() => setPopOpen(false)}
              >
                <Grid.Column textAlign="right" width={7} className={styles.pt1}>
                  Name:
                </Grid.Column>
                <Grid.Column width={8} className={styles.pt1}>
                  {user?.first_name}, {user?.last_name}
                </Grid.Column>
              </Grid>
              <Grid
                verticalAlign="middle"
                className={styles.mt0}
                onClick={() => setPopOpen(false)}
              >
                <Grid.Column textAlign="right" width={7} className={styles.pt1}>
                  UserName:
                </Grid.Column>
                <Grid.Column width={8} className={styles.pt1}>
                  {user?.username || 'JohnDeo'}
                </Grid.Column>
              </Grid>
            </>
          }
        />
      )}
    </>
  );
};

export { ATMUserMenu };
