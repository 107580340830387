import React from 'react';
import { Pagination, PaginationProps } from 'semantic-ui-react';
import { ATMIcon } from '../ATMIcon/ATMIcon.component';

export type IATMPaginationProps = PaginationProps;

export const ATMPagination: React.FC<PaginationProps> = ({
  defaultActivePage,
  totalPages,
  ...props
}) => (
  <Pagination
    defaultActivePage={defaultActivePage}
    totalPages={totalPages}
    ellipsisItem={{
      content: (
        <ATMIcon name="ellipsis horizontal" style={{ color: '#bcb8b8' }} />
      ),
      icon: true,
    }}
    firstItem={{ content: <ATMIcon name="angle double left" />, icon: true }}
    lastItem={{ content: <ATMIcon name="angle double right" />, icon: true }}
    prevItem={{ content: <ATMIcon name="angle left" />, icon: true }}
    nextItem={{ content: <ATMIcon name="angle right" />, icon: true }}
    {...props}
  />
);
