import React, { useMemo } from 'react';
import Countdown from 'react-countdown';

export type IMOLCountDownProps = {
  date: any;
  renderer: any;
};

export const valueFunction = (
  value:
    | Date
    | string
    | number
    | ((h: string, m: string, s: string, completed: boolean) => React.ReactNode)
): any => {
  return value;
};

const MOLCountDown: React.FC<IMOLCountDownProps> = (props) => {
  const date = useMemo(() => valueFunction(props.date), props.date);
  const renderer = useMemo(() => valueFunction(props.renderer), props.renderer);
  return <Countdown date={date} renderer={renderer} />;
};

export { MOLCountDown };
