import React from 'react';
import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

const mediaStyles = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

export type devices =
  | 'mobile'
  | 'tablet'
  | 'computer'
  | 'largeScreen'
  | 'widescreen';

interface responsiveProps {
  media?: devices;
  greaterThan?: devices;
}

const ATMResponsive: React.FC<responsiveProps> = ({ children, ...props }) => (
  <>
    <style>{mediaStyles}</style>
    <MediaContextProvider>
      {props.media !== undefined ? (
        <Media at={props.media}>
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? children : null;
          }}
        </Media>
      ) : (
        <Media greaterThan={props.greaterThan}>
          {(mediaClassNames, renderChildren) => {
            return renderChildren ? children : null;
          }}
        </Media>
      )}
    </MediaContextProvider>
  </>
);

export { ATMResponsive };
