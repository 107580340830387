import React from 'react';
import styles from './MOLReadonlyField.module.scss';
import { ATMPopover } from '../../atoms/ATMPopover/ATMPopover.component';
import { ATMIcon } from '../../atoms/ATMIcon/ATMIcon.component';

export type IMOLReadonlyFieldProps = {
  label: string;
  value: any;
  inline?: boolean;
  truncate?: boolean;
  icon?: boolean;
};

const MOLReadonlyField: React.FC<IMOLReadonlyFieldProps> = ({
  label,
  value,
  inline = true,
  truncate = false,
  icon = false,
}) => {
  return (
    <div className={inline ? styles.displayInline : styles.displayBlock}>
      {truncate ? (
        <ATMPopover
          inverted
          className={styles.delay}
          trigger={
            <div className={truncate ? styles.truncate : styles.labelTag}>
              {label}{' '}
            </div>
          }
          content={label}
          size="mini"
        />
      ) : (
        <div className={truncate ? styles.truncate : styles.labelTag}>
          {label}
          {icon && !inline && (
            <span>
              <ATMIcon className={styles.molReadOnlyIcon} name="info circle" />
            </span>
          )}
        </div>
      )}
      {inline && <span>:</span>}

      <div className={inline ? styles.displayInline : styles.displayBlock}>
        {inline ? (
          <div className={styles.valueTag}>
            {value}
            {icon && (
              <span>
                {' '}
                <ATMPopover
                  inverted
                  className={styles.delay}
                  trigger={
                    <ATMIcon
                      className={styles.molReadOnlyIcon}
                      name="info circle"
                    />
                  }
                  content={value}
                  size="mini"
                />
              </span>
            )}
          </div>
        ) : (
          <div className={styles.blockvalueTag}>{value}</div>
        )}
      </div>
    </div>
  );
};

export { MOLReadonlyField };
