// eslint-disable-next-line consistent-return
export function validateURL(url) {
  const forbiddenHrefChars = ['"', "'", '`', '(char)10', '(char)13'];
  // char 10 is Line Feed character and char 13 is Carriage Return character.
  const acceptedSchemes = ['https:', 'http:', 'mailto:', '#', 'file:'];
  const forbiddenBeforeQueryString = [':', '&', '\\'];
  const qsIdx = url.indexOf('?');
  const partBeforeQueryString = qsIdx < 0 ? url : url.substring(0, qsIdx);
  const parsed = new URL(url);
  if (forbiddenHrefChars.includes(url)) return false;
  if (acceptedSchemes.includes(parsed.protocol)) return true;
  if (forbiddenBeforeQueryString.includes(partBeforeQueryString)) return false;
}

export const restrictAngularBracketSymbols = (value) => {
  const newValue = value?.toString().replace(/[<>]/g, '');
  return newValue;
};
export const restrictDecimalSymbol = (value) => {
  const newValue = value?.toString().replace(/[.]/g, '');
  return newValue;
};

export const restrictNegativeSignSymbol = (value) => {
  const newValue = value?.toString().replace(/[-]/g, '');
  return newValue;
};

export const restrictAlphabetsAndSpclChar = (value) => {
  const newValue = value
    ?.toString()
    .replace(/[a-zA-Z&,!@#$%^&*()_+=:;""?/{}|]/g, '');
  return newValue;
};
