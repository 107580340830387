import React, { useState, useEffect, useCallback } from 'react';
import { TextArea, TextAreaProps } from 'semantic-ui-react';
import { restrictAngularBracketSymbols } from '../../../utils/security';
import styles from './ATMTextArea.module.scss';

export type IATMTextAreaProps = TextAreaProps & {
  charCount?: boolean;
  charLimit?: number;
  restrictAngularBrackets?: boolean;
};

export const ATMTextArea: React.FC<IATMTextAreaProps> = ({
  children,
  charCount = false,
  charLimit = 140,
  restrictAngularBrackets = true,
  ...props
}) => {
  const { value: originalValue } = props;
  const [chars_left, setContent] = useState(0);
  const [value, setValue] = useState(originalValue);

  useEffect(() => {
    let count = 0;

    if (props.value) {
      count = props.value?.toString().length;
    }

    setValue(props.value);
    setContent(count);
  }, [props.value, setValue, setContent]);

  const handleWordCount = useCallback(
    (event, data) => {
      if (props.onChange) {
        props.onChange(event, { ...data, value: event.target.value });
      }

      const count = restrictAngularBrackets
        ? restrictAngularBracketSymbols(event.target.value).length
        : event.target.value.length;

      if (restrictAngularBrackets) {
        setValue(restrictAngularBracketSymbols(event.target.value));
      } else {
        setValue(event.target.value);
      }
      setContent(count);
    },
    [setValue, setContent]
  );

  const handleTextInput = useCallback(
    (event, data) => {
      if (props.onChange) {
        props.onChange(event, { ...data, value: event.target.value });
      }

      if (restrictAngularBrackets) {
        setValue(restrictAngularBracketSymbols(event.target.value));
      } else {
        setValue(event.target.value);
      }
    },
    [setValue]
  );

  if (charCount) {
    return (
      <div>
        <TextArea
          {...{
            ...props,
            maxLength: charLimit,
            onChange: handleWordCount,
            value,
          }}
        />
        <div className={styles.characterCount}>
          {chars_left}/{charLimit}
        </div>
      </div>
    );
  }

  return (
    <TextArea
      {...{
        ...props,
        onChange: handleTextInput,
        value,
      }}
    />
  );
};
