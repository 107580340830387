import React from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMToggle.module.scss';

export type IATMToggleProps = CheckboxProps & {
  size?: string;
};

export const ATMToggle: React.FC<CheckboxProps> = ({ ...props }) => (
  <Checkbox
    toggle
    {...props}
    className={classNames(props.className, styles.toggleContainer)}
  />
);
