import React, { useState, useEffect } from 'react';
import { Visibility, Grid, GridColumn, Segment } from 'semantic-ui-react';
import { ATMButton } from '../../atoms/ATMButton/ATMButton.component';
import styles from './ORGHelpPage.module.scss';
import { ATMMenu } from '../../atoms/ATMMenu/ATMMenu.component';
import { ATMResponsive } from '../../atoms/ATMResponsive/ATMResponsive.component';

export const adminMenuOptions = [
  { key: 'role-maintenance', name: 'Introduction' },
  { key: 'search-feature', name: 'Search Feature' },
  { key: 'at-a-glance', name: 'At-a-glance' },
  { key: 'activity-log-screen', name: 'Activity Log' },
  { key: 'invoice-snapshot', name: 'Invoice Snapshot' },
  { key: 'debugging-issues', name: 'Debugging Issues' },
  { key: 'other-topics', name: 'Other Topics' },
];

const DesktopContainer: React.FC<any> = ({ children }) => {
  const [activeItem, setactiveState] = useState<string>('role-maintenance');

  const handleClick = (tabName: string) => {
    setactiveState(tabName);
    const clickValueId: any | null = document.getElementById(tabName);
    const offset = 65;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = clickValueId.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  const isScrolledIntoView = (el) => {
    const { top, bottom } = el.getBoundingClientRect();
    const elemTop = parseFloat(top) + 50;
    const elemBottom = bottom;
    const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      adminMenuOptions.map((adminMenuOption) => {
        const el = document.getElementById(adminMenuOption.key);
        if (isScrolledIntoView(el)) {
          setactiveState(adminMenuOption.key);
        }
      });
    });
  }, []);

  return (
    <div>
      <Visibility once={false}>
        <div className={styles.wrapper}>
          <div className={styles.topHeader}>
            <div>
              <ul className={styles.branding}>
                <li className={styles.logoOther}>Major Markets System</li>
                <li className={styles.logoOther}>Help</li>
              </ul>
            </div>
          </div>
        </div>
      </Visibility>
      <Grid className={styles.adminContainer}>
        <GridColumn computer={4} tab={6} mobile={16}>
          <ATMMenu
            vertical
            collapsible
            billViewer
            className={styles.sidemenubar}
          >
            {adminMenuOptions.map((menu_option) => {
              return (
                <ATMMenu.Item
                  key={menu_option.key}
                  name={menu_option.key}
                  content={menu_option.name}
                  active={activeItem === menu_option.key}
                  onClick={() => handleClick(menu_option.key)}
                />
              );
            })}
          </ATMMenu>
        </GridColumn>
        <GridColumn computer={12} tab={10} mobile={16}>
          <Grid>
            <GridColumn computer={8} tab={10} mobile={16}>
              Need help with any topic? Find here
            </GridColumn>
            <GridColumn computer={8} tab={6} mobile={16} textAlign="right">
              <ATMButton secondary size="small">
                Contact Support
              </ATMButton>
            </GridColumn>
          </Grid>
          <Segment>
            <Grid className={styles.billintroduction} id="role-maintenance">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Bill Viewer Introduction</h5>
                  <p>
                    Bill Viewer is a intranet application that allows Account
                    Executives to view their customers bills and other account
                    information online. It is easy and fast. System requirements
                    are Windows 2000, Internet Explorer 5.5 and Adobe 4.0. In
                    most screens, you simply place your cursor on the line you
                    are interested in viewing, and then click twice. Or click
                    once on the icon to the left of the line.
                  </p>
                  <p>
                    After logging in, click on the sunglasses icon to begin your
                    search. The user is then presented an At a Glance view of
                    the account. From here, click on the activity log link to
                    see the accounts billing activity.
                  </p>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction}>
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Logon/Password Help</h5>
                  <p>
                    Forgot your password?
                    <br />
                    Your password will need to be reset. Contact the SCBS
                    Support.
                  </p>
                  <p>
                    Need Access to the Bill Viewer?
                    <br />
                    You may obtain access to the program by contacting the SCBS
                    Support.
                  </p>
                  <p>
                    Username
                    <br />
                    Your user name is your company TP address and is the same as
                    your CCS username.
                  </p>
                  <p>
                    Passwords
                    <br />
                    Passwords should be between 6-12 characters. Passwords are
                    case-sensitive, make sure you enter it as you first created
                    it (e.g. lower case or caps or combination). Make sure you
                    do not have the insert key on when entering your password.
                  </p>
                  <p>
                    Changing Passwords - Click on the &quot;Change
                    password&quot; box before selecting the enter button on the
                    Logon Screen. You will automatically be prompted for a new
                    password every 90 days.
                  </p>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction} id="search-feature">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Search Feature</h5>
                  <p>
                    Search Criteria box
                    <br />
                    You can search by one of the following:
                    <ul>
                      <li>Account Name</li>
                      <li>BAID</li>
                      <li>Account Number</li>
                    </ul>
                  </p>
                  <h5>Searching By Account Name -</h5>
                  <p>
                    Type a minimum of three letters of either the customer name
                    or facility name. The system will provide you with all the
                    accounts listed under the name searched. Then select the
                    account from the Search Results screen. Click once, on the
                    icon on the left, or twice on the row you wish to access
                  </p>
                  <h5>Searching by BAID or account number -</h5>
                  <p>
                    Type in the entire number. Format your search using the
                    dashes or type the BAID (or account number) without the
                    dashes. Once you type in a specific BAID or account number,
                    the system will provide you with the invoices related to
                    that account number. If you enter more than one search
                    criterion, the search will use only the first criterion
                    entered (i.e. the account name before the BAID or the BAID
                    before the account number).
                  </p>
                  <h5>Search Results Screen</h5>
                  <p>
                    This screen displays the results for the search criteria you
                    entered. The active accounts are displayed in black and the
                    closed accounts will be displayed in gold. The accounts are
                    grouped by facility (service address) with the most current
                    account for a facility listed first.
                  </p>
                  <p>
                    Place your cursor anywhere on the account you are interested
                    in viewing and click twice. Or click once on the icon to the
                    left of the account name.
                  </p>

                  <p>
                    The dial icons to the left display in four colors, depending
                    on your security access.
                    <br />
                    &nbsp;&nbsp;A red dial displays when you do not have access
                    to account requested.
                    <br />
                    &nbsp;&nbsp;A green dial displays for accounts assigned to
                    you in the Customer Contracts System.
                    <br />
                    &nbsp;&nbsp;A blue dial displays for accounts that belong to
                    another Account Executive &nbsp;&nbsp;who also reports to
                    the same &nbsp;&nbsp;Supervisor as yourself. You have access
                    to view the account&apos;s billing history and invoice
                    images for those &nbsp;&nbsp;accounts with green and blue
                    dials.
                    <br />
                    &nbsp;&nbsp;A yellow dial displays when an account does not
                    have anyone assigned to it. If this is your account, talk to
                    &nbsp;&nbsp;your Sales Manager to get this account assigned
                    to you.
                  </p>
                  <p>
                    To get access to an account with a red dial , you will need
                    to be listed as the Account Executive assigned to this
                    account in CCS. Talk to your Sales Manager about account
                    assignments.
                  </p>
                  <h5>
                    What if I&apos;m the assigned AE, but I get a red dial on my
                    account?
                  </h5>
                  <p>
                    1. Make sure you are logged in using the same (TP)id as you
                    use for CCS. Security is based on the TPID of the account
                    executive assigned in CCS.
                    <br />
                    2. Check CCS to see if you the assigned Account Executive to
                    the account. If you are not, contact your Sales Manager to
                    get assigned to that account.
                    <br />
                    3. If the account was just assigned to you, your profile has
                    changed, or you just logged into a machine after someone
                    else used the application, then, it is possible that the
                    original view of the page is still saved on your computer.
                    Clear out your cache using the following steps: In Internet
                    Explorer, go to Tools, Internet Options. Select Settings,
                    Every visit to the page and Ok. If this works, e-mail
                    SCBSSupport to report your problem. This should problem is
                    also related to another security issue that should also be
                    corrected.
                    <br />
                    4. If you still get a red dial after reviewing the above
                    items, get help from an another Account Executive or your
                    Sales Manager to make sure you have done all the above steps
                    correctly. If these four steps do not help, contact your
                    computer system support personnel. Your internet setup may
                    not be able to access company network resources properly.
                  </p>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction} id="at-a-glance">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>At-a-glance</h5>
                  <p>
                    This link provides you with a log of account activities. The
                    first item displayed is the last activity or event created
                    and saved. This is the default. If additional details are
                    available for any transactions, an icon will be displayed on
                    the left. See list of icons for the meaning of the icons.
                  </p>
                  <h5>Sorting Activity</h5>
                  <p>
                    You may change the order of the items displayed to show the
                    oldest events first. To do this, click on either the Process
                    Date arrow or the From Date arrow located on the right of
                    the fieldname.
                  </p>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction} id="activity-log-screen">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Activity Log Screen</h5>
                  <p>
                    This link provides you with a log of account activities. The
                    first item displayed is the last activity or event created
                    and saved. This is the default. If additional details are
                    available for any transactions, an icon will be displayed on
                    the left. See list of icons for the meaning of the icons.
                  </p>
                  <h5>Sorting Activity</h5>
                  <p>
                    You may change the order of the items displayed to show the
                    oldest events first. To do this, click on either the Process
                    Date arrow or the From Date arrow located on the right of
                    the fieldname.
                  </p>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction} id="invoice-snapshot">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Field Description</h5>
                  <p>
                    <li>
                      Process Date - The date an invoice or other activity was
                      created and saved by the Billing Analyst or system.
                    </li>
                    <li>Transaction Description - Brief Title.</li>
                    <li>
                      Effective Date - Start date for the Billing Period for
                      invoices and effective date for all other transactions.
                    </li>
                    <li>
                      Date Mailed - Date invoice was sent by U.S. mail
                      (applicable only to invoice transactions).
                    </li>
                    <li>
                      Total Therms - Total therms used by the customer for the
                      month (applicable only to invoice transactions).
                    </li>
                    <li>
                      Amount - Total for the payment, or charge transactions.
                      Previous balances are not included in the invoice
                      transaction amount.
                    </li>
                  </p>
                  <strong>Changing the View Option -</strong>At the top of this
                  screen, the AE has the three viewing options:
                  <br /> <br />
                  <li>
                    List of Mailed Invoices and Payment Transactions - This is
                    the default view. This is a list of only the invoices mailed
                    to customer and payments made by the customer. This list may
                    include several invoices for the same month. However, at any
                    time, there will only be one valid invoice recorded in our
                    revenue system.
                  </li>
                  <li>
                    List of All Invoices - This is a list of all invoices that
                    were created and saved, including those that were not mailed
                    to the customer due to errors. Several invoices may appear
                    for the same month. However, at any time, there will only be
                    one invoice recorded in our revenue system.This option also
                    includes a view of the invoices as they were saved for
                    revenue in those instances where a customer`&apos;s invoice
                    differs from our accounting revenue recording practices.
                  </li>
                  <li>
                    Last Corrected Mailed Invoices - This is a list of only the
                    last corrected invoice for each month that was mailed to the
                    customer. This option eliminates from view any invoices
                    which were superseded with a corrected invoice.
                  </li>
                  <li>
                    List All - List of all activity logged for the account. This
                    is a list of all transactions that were created and saved,
                    including invoices that were not mailed to the customer due
                    to errors. Several invoices may appear for the same month.
                    However, at any time, there will only be one invoice
                    recorded in our revenue system.This option also includes a
                    view of the invoices as they were saved for revenue in those
                    instances where a customer`&apos;s invoice differs from our
                    accounting revenue recording practices.
                  </li>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction} id="debugging-issues">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Activity Log Screen</h5>
                  <p>
                    This link provides you with a log of account activities. The
                    first item displayed is the last activity or event created
                    and saved. This is the default. If additional details are
                    available for any transactions, an icon will be displayed on
                    the left. See list of icons for the meaning of the icons.
                  </p>
                  <h5>Sorting Activity</h5>
                  <p>
                    You may change the order of the items displayed to show the
                    oldest events first. To do this, click on either the Process
                    Date arrow or the From Date arrow located on the right of
                    the fieldname.
                  </p>
                </div>
              </GridColumn>
            </Grid>
            <Grid className={styles.billintroduction} id="other-topics">
              <GridColumn computer={16} tab={16} mobile={16}>
                <div>
                  <h5>Viewing Invoices</h5>
                  <p>
                    For invoice transactions, you can see the image of the bill
                    if the envelope icon is shown. Or a quick snapshot of the
                    bill if the camera is shown.
                  </p>
                  <br />
                  <br />
                  <p>
                    To view the image of the bill, place your cursor on the
                    invoice line you are interested in viewing and click twice.
                    Or click once on the icon. An Adobe Reader PDF file will
                    automatically open up the invoice selected. After viewing,
                    you can exit out of the PDF file or you can leave it open.
                    This feature allows the AE to view several invoices at one
                    time for the same customer.
                  </p>
                  <br /> <br />
                  <p>
                    To view the snapshot of the bill image information, click
                    once on the camera icon. The quick snapshot of the bill
                    contains current status of bill, detailed charge information
                    and the imbalance position from the bill image.
                  </p>
                  <br /> <br />
                  <p>
                    Download Capability -You can download the information on
                    this screen to an Excel spreadsheet. Click on the Download
                    icon on the top right hand side of the screen. Save the
                    temporary file as an &quot;Excel workbook&quot; file to your
                    local drive. (Excel by default only shows the time portion
                    of the date timestamp. To see the date portion of the
                    process date in the first column, you will need to format
                    the date in Excel, Select the column, click on Format,
                    Cells, Date and then select the format (mm/dd/yy)).
                  </p>
                </div>
              </GridColumn>
            </Grid>
          </Segment>
        </GridColumn>
      </Grid>
      {children}
    </div>
  );
};

const ORGHelpPage: React.FC<any> = ({ children, ...props }) => {
  return (
    <div>
      <ATMResponsive greaterThan="mobile">
        <DesktopContainer {...props}>{children}</DesktopContainer>
      </ATMResponsive>
    </div>
  );
};

export { ORGHelpPage };
