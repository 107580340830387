import React from 'react';
import { TabProps, Tab, TabPaneProps } from 'semantic-ui-react';

export interface IATMTab extends React.FC<TabProps> {
  Pane: typeof Tab.Pane;
}

const ATMTab: IATMTab = ({ children, ...props }) => (
  <Tab {...props}>{children}</Tab>
);

ATMTab.Pane = Tab.Pane;

export type IATMTabProps = TabProps;
export type IATMTabPaneProps = TabPaneProps;

export { ATMTab };
