import React, { ReactElement } from 'react';
import { Dimmer } from 'semantic-ui-react';
import { ATMModal } from '../ATMModal/ATMModal.component';
import { ATMButton } from '../ATMButton/ATMButton.component';
import { ATMHeader } from '../ATMHeader/ATMHeader.component';
import { ATMLoader } from '../ATMLoader/ATMLoader.component';

export interface ConfirmationModalProps {
  openModal?: () => void;
  closeModal: () => void;
  action: () => void;
  isActive: boolean;
  loader?: boolean;
  negativeButton?: boolean;
  cancelButtonContent: string;
  confirmButtonContent: string;
  modalContent: string | ReactElement;
  headerContent?: string;
}

const ATMConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  return (
    <ATMModal
      onOpen={props.openModal}
      onClose={props.closeModal}
      open={props.isActive}
      data-testid="confirmation-modal"
      size="tiny"
    >
      <ATMModal.Header>
        <ATMHeader as="h1">{props.headerContent}</ATMHeader>
      </ATMModal.Header>
      <ATMModal.Content>
        {props.loader && (
          <Dimmer active inverted>
            <ATMLoader active />
          </Dimmer>
        )}
        <div>{props.modalContent}</div>
      </ATMModal.Content>
      <ATMModal.Actions>
        <ATMButton secondary onClick={props.closeModal}>
          {props.cancelButtonContent}
        </ATMButton>
        <ATMButton
          data-testid="confirmation-submit"
          negative={props.negativeButton ? props.negativeButton : false}
          primary={!props.negativeButton}
          onClick={props.action}
        >
          {props.confirmButtonContent}
        </ATMButton>
      </ATMModal.Actions>
    </ATMModal>
  );
};

export { ATMConfirmationModal };
