// Call the component at the top most level of the application.

import React, { useState, ReactElement } from 'react';
import {
  Message,
  MessageProps,
  MessageContentProps,
  MessageHeaderProps,
  MessageItemProps,
  MessageListProps,
} from 'semantic-ui-react';
import { ATMLabel } from '../ATMLabel/ATMLabel.component';
import styles from './ATMEnvironmentMessage.module.scss';

export type IATMEnvProps = MessageProps & {
  envType: string;
  content?: ReactElement;
};

export interface IATMENvMessage extends React.FC<IATMEnvProps> {
  Content: typeof Message.Content;
  Header: typeof Message.Header;
  Item: typeof Message.Item;
  List: typeof Message.List;
}

const ATMEnvironmentMessage: IATMENvMessage = (props) => {
  const [viewLabel, setLabel] = useState(false);

  return (
    <div>
      <div>
        {!viewLabel ? (
          <Message className={styles.envBanner} size="tiny" {...props}>
            <div className={styles.envMessage}>
              <p>
                {' '}
                <b>
                  You are currently viewing {props.envType} Environment and it
                  is for testing purpose only
                </b>
                <p>
                  All Information shown in this page are sample data. Please use
                  production environment for official use.
                </p>
              </p>
              <a
                className="sempraPrimaryLink"
                role="button"
                onClick={() => setLabel(true)}
              >
                Hide
              </a>
            </div>
          </Message>
        ) : (
          <ATMLabel className={styles.envLabel}>
            Testing purpose only.{' '}
            <a
              className="labelLink"
              role="button"
              onClick={() => setLabel(false)}
            >
              Show details
            </a>
          </ATMLabel>
        )}
      </div>
    </div>
  );
};

ATMEnvironmentMessage.Content = Message.Content;
ATMEnvironmentMessage.Header = Message.Header;
ATMEnvironmentMessage.Item = Message.Item;
ATMEnvironmentMessage.List = Message.List;

export type IATMENvMessageContentProps = MessageContentProps;
export type IATMENvMessageHeaderProps = MessageHeaderProps;
export type IATMENvMessageItemProps = MessageItemProps;
export type IATMENvMessageListProps = MessageListProps;

export { ATMEnvironmentMessage };
