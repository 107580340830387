import React from 'react';
import { Checkbox, CheckboxProps } from 'semantic-ui-react';
import classNames from 'classnames';
import styles from './ATMCheckbox.module.scss';

export type IATMCheckboxProps = CheckboxProps & {
  custom?: boolean;
};

export const ATMCheckbox: React.FC<IATMCheckboxProps> = ({ ...props }) => (
  <Checkbox
    className={classNames(props.className, {
      [styles.customCheckbox]: props.custom,
    })}
    {...props}
  />
);
